import Axios, { AxiosError, AxiosInstance, CancelTokenSource } from "axios";
import store from "../redux/store";
import {
  PublicCandidate,
  ProcessPhase,
  TenantSendUser,
  Profession,
  SendUpdateTemplate,
  SendUpsertIndustry,
  CammioSettings,
  Skill,
  FeatureConfigurationType,
  Candidate,
  Client,
  LiveInterviewExtra,
  Branch,
  Region,
  TenantsAdmin,
  ScreeningQuestionLibrary,
  ReferenceCheckLibrary,
  Position,
  ContractType,
  VideoLibraryI,
  Zone,
  SendUpsertTag,
  SendUpsertLocalTag,
  Calendar,
  NonAvailabilityCalendar,
  CalendarAppointment,
  PhaseType,
  JobDescriptionBoard,
  UserFeedback,
  Division,
  BusinessLine,
  PhaseCancelled,
  ClientBrand,
  NewClientBrandData,
  OfferTemplate,
  JobDescriptionSectionCategory,
  UserProperties,
  SendEmailWelcomeMessage,
  AdditionalDocuments,
} from "../types";
import { APIM_KEY, API_BASE_PATH, FiltersResults, ORDER_AD_USER_NAME_ASC, TABLE_SIZE } from "../constants";
import { toLiteral } from "../helper/locale-utils";
import { saveAs } from "file-saver";
import { ServiceCreationI, VideoDictionary } from "../types/service-types";
import { FormInterface } from "../components/candidate-web/components/reference-form";
import { getBasicHeaders } from "./helper";
import { TenantSMSVars } from "../components/administrator/manage-tenant-sms-templates-var/components/table";
import { addNotification } from "../components/shared/notifications/notifications-service";
import { ProcessTemplateFilters } from "../components/home/components/home-process-templates/home-process-templates";
import Filters from "../components/shared/results/filters/filters";

enum CUSTOM_TENANTS {
  USA = 101,
}

// GENERATE HTTP
export function http(branchHeaders: boolean = false, overwriteHeaders: any = {}, cancelToken?: CancelTokenSource): AxiosInstance {
  const options: any = {
    baseURL: API_BASE_PATH,
    headers: getBasicHeaders(branchHeaders, overwriteHeaders),
    withCredentials: true,
  };
  if (cancelToken?.token) options.cancelToken = cancelToken.token;
  return Axios.create(options);
}

function showError(errorMessageToShow: string, e: any) {
  let message = errorMessageToShow + e.message;
  console.error(message, e);
  throw new Error(message);
}
export function showCatch(e: any, successMessage: string, errorMessageToShow: string, showErrorName?: boolean) {
  let message;
  if (e.isAxiosError) {
    const ae = e as AxiosError;
    const errorMessage = checkForAccessErrors(ae);
    if (errorMessage !== "") {
      message = errorMessage;
    } else {
      let responseStatusText;
      if (ae?.response?.data) {
        responseStatusText = JSON.stringify(ae.response.data?.data);
      } else {
        responseStatusText = ae && ae.response ? ae.response.statusText : "STATUS_TEXT_UNKNOWN";
      }
      message = successMessage + responseStatusText;
      if (ae?.response?.data?.errorName && showErrorName) {
        message = message + " Error name: " + ae?.response?.data?.errorName;
      }
    }
  } else {
    message = errorMessageToShow + e.message;
  }
  console.error(message, e);
  throw new Error(message);
}

function showUploadCatch(e: any, successMessage: string, errorMessageToShow: string) {
  let message;
  if (e.isAxiosError) {
    const ae = e as AxiosError;
    const errorMessage = checkForAccessErrors(ae);
    if (errorMessage !== "") {
      message = errorMessage;
    } else {
      const uploadError = checkForUploadError(ae);
      if (uploadError !== "") {
        message = uploadError;
      } else {
        const responseStatusText = ae && ae.response ? ae.response.statusText : "STATUS_TEXT_UNKNOWN";
        const responseStatus = ae && ae.response ? ae.response.status : "STATUS_UNKNOWN: " + responseStatusText;
        message = successMessage + responseStatus;
      }
    }
  } else {
    message = errorMessageToShow + e.message;
  }
  console.error(message, e);
  throw new Error(message);
}

export async function uploadVideo(name: string, file: File, checksum: string, thumbnail: string, type: string) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", name);
  bodyFormData.append("file", file);
  bodyFormData.set("checksum", checksum);
  if (thumbnail && thumbnail.trim().length > 0) bodyFormData.set("thumbnail", thumbnail);
  let response;
  try {
    response = await http().post(`/api/file?type=${type}&rcStorage=true`, bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload video. Response ", "Upload video. ");
  }
}

export async function deleteVideo(id: string, type?: string, temp?: boolean) {
  let response;
  try {
    response = await http().delete(`/api/videos/${id}?type=${type}&temp=${temp ? temp : false}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete video. Response ", "Delete video. ");
  }
}

export async function uploadImage(name: string, file: File, checksum: string, type: string) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", name);
  bodyFormData.append("file", file);
  bodyFormData.set("checksum", checksum);

  let response;
  try {
    response = await http().post(`/api/file?type=${type}&rcStorage=true`, bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload image. Response ", "Upload image. ");
  }
}

export async function deleteImage(id: string, type?: string, temp?: boolean) {
  let response;
  try {
    response = await http().delete(`/api/images/${id}?type=${type}&temp=${temp ? temp : false}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete image. Response ", "Delete image. ");
  }
}

//Fetch interview videos
export async function fetchMediaUrl(fetchUrl: any, errorMessageToShow: string) {
  try {
    let response;
    response = await http().get(fetchUrl);
    return response.data;
  } catch (e) {
    showError(errorMessageToShow, e);
  }
}

export async function fetchVideoUrl(fetchUrl: string) {
  return fetchMediaUrl(fetchUrl, "Video Interview error. ");
}

export async function fetchCandidatePresentationVideo(fetchUrl: string) {
  return fetchMedia(fetchUrl, "Fetch Candidate Presentattion Video error. ");
}

//Fetch public medias
const videoDictionary: VideoDictionary[] = [];

export async function fetchMedia(fetchUrl: any, errorMessageToShow: string) {
  // not tested
  try {
    const headers: any = getBasicHeaders(false, {});
    const video = videoDictionary.find((ind: VideoDictionary) => ind.url === fetchUrl);
    // Patch to limit request:
    let response;
    if (video?.video) {
      response = video.video.clone();
    } else {
      response = await fetch(fetchUrl, {
        headers: headers,
      });
      videoDictionary.push({
        url: fetchUrl,
        video: response?.clone(),
      });
    }

    return response;
  } catch (e) {
    showError(errorMessageToShow, e);
  }
}

export async function fetchImage(fetchUrl: string) {
  return fetchMedia(fetchUrl, "Fetch Image error. ");
}

export async function fetchVideo(fetchUrl: string) {
  return fetchMedia(fetchUrl, "Video Interview error. ");
}

export async function updateCandidateHired(processId: string, candidateId: string, hired: boolean) {
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId, { hired: hired });
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Hired error. Response ", "Candidate Hired error. ");
  }
}

/**
 * Function that close a process
 * @param {*} id id of the process
 */
export async function closeProcesses(id: string) {
  let response;
  try {
    response = await http().put("/api/processes/" + id, {
      expiryDate: Date.now(),
      status: "closed",
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Process Close error. Response ", "Process Close error. ");
  }
}

/**
 * Function that search and order the process list according to the parameters
 * @param {*} page number of page
 * @param {*} size size of elements for page
 * @param {*} order order the elements in page
 * @param {*} filter text to filter the elements
 *
 * @return {list}
 */
export async function fetchProcesses(
  page: number,
  size: number,
  order: any,
  filter: any,
  user: boolean,
  branch: string,
  onlyDrafts: boolean,
  favouriteProcesses: boolean,
  processStatusFilter?: string,
  processPhasesFilter?: string
) {
  const params = { page, size, order, filter, user, branch, onlyDrafts, processStatusFilter, favouriteProcesses, processPhasesFilter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  if (params.branch === null || params.branch === undefined || params.branch === "") delete params.branch;
  if (params.processStatusFilter === null || params.processStatusFilter === undefined || params.processStatusFilter === "") delete params.processStatusFilter;
  if (params.processPhasesFilter === null || params.processPhasesFilter === undefined || params.processPhasesFilter === "") delete params.processPhasesFilter;
  let response;
  try {
    response = await http().get("/api/processes", { params: params });

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function fetchProcessActives(page: number, size: number, order: any, filter: any, processId?: string) {
  const params = { page, size, order, filter, processId };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  if (params.processId === null || params.processId === undefined || params.processId === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/processes/actives", { params: params });

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

/**
 * Function that search and order the all candidates according to the parameters for process with same id
 * @param {*} id id of process where search the candidates
 * @param {*} page number of page
 * @param {*} size number of elements in page
 * @param {*} order order the elements in page
 * @param {*} filter text to filter the elements
 *
 * @return {list}
 */
export async function fetchCandidatesForProcess(
  id: string,
  page: number,
  size: number,
  order: any,
  field: string,
  filter: any,
  filterTag: any,
  filterTagsType: string,
  filterPhases: any[],
  filterAnyPending: boolean,
  initDate?: number,
  endDate?: number,
  screeningFilter?: any[]
) {
  let params = {
    page,
    size,
    order,
    filterTag,
    filterPhases,
    filterTagsType,
    filterAnyPending,
    initDate,
    endDate,
    screeningFilter,
  };
  if (!initDate) {
    delete params.initDate;
  }
  if (!endDate) {
    delete params.endDate;
  }

  if (filter) {
    if (!field) field = "filter";
    params = { ...params, [field]: filter };
  }
  let response;
  try {
    response = await http().get("/api/processes/" + id + "/candidates", {
      params: params,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

/**
 * Function that search and order the all filtered candidates according to the parameters for process with same id
 * @param {*} id id of process where search the candidates
 * @param {*} order order the elements in page
 * @param {*} filter text to filter the elements
 *
 * @return {list}
 */
export async function fetchAllFilteredCandidatesForProcess(
  id: string,
  order: any,
  field: string,
  filter: any,
  filterTag: any,
  filterTagsType: string,
  filterPhases: any[],
  filterAnyPending: boolean,
  initDate?: number,
  endDate?: number
) {
  let params = {
    order,
    filterTag,
    filterTagsType,
    filterPhases,
    filterAnyPending,
    initDate,
    endDate,
  };

  if (!initDate) {
    delete params.initDate;
  }
  if (!endDate) {
    delete params.endDate;
  }
  if (filter) {
    if (!field) field = "filter";
    params = { ...params, [field]: filter };
  }

  let response;
  try {
    response = await http().get("/api/processes/" + id + "/candidates/filtered", {
      params: params,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function fetchShortlistCandidates(processId: string, phaseIndex: string) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/shortlist/" + phaseIndex);

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function shareShortlistCandidates(
  processId: string,
  phaseIndex: string,
  shareContent: { email: string; candidates: any; message: string; groupId: string; candidatePdfs: { base64: string; filename: string }[] }
) {
  const formData = new FormData();
  formData.set("email", shareContent.email);
  formData.set("candidates", JSON.stringify(shareContent.candidates));
  formData.set("contentTypeSecondaryLogo", shareContent.message);
  formData.set("groupId", shareContent.groupId);
  formData.set("message", shareContent.message);
  shareContent.candidatePdfs.forEach((file, i) => {
    formData.set(`candidatePdfBlob${i}`, file.base64);
    formData.set(`candidatePdfFilename${i}`, file.filename);
  });

  try {
    return (await http().post("/api/processes/" + processId + "/candidates/shortlist/" + phaseIndex, formData)).data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function getShortlistGroupReview(groupId: string) {
  let response;
  try {
    response = await http().get("/api/shortlist/" + groupId);

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}
export async function putShortlistGroupReview(groupId: string, candidates: any) {
  let response;
  try {
    response = await http().put("/api/shortlist/" + groupId, { candidates });

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function getCategoryTags() {
  let response;
  try {
    response = await http().get("/api/categoryTags");

    return response.data;
  } catch (e) {
    showCatch(e, "Get Category Tag error. Response ", "Get Category Tag error. ");
  }
}

export async function updateCategoryTags(tagId: string, localeName: string) {
  let response;
  try {
    response = await http().put("/api/categoryTags/" + tagId + "/language", {
      localeName,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Put Category Tag error. Response ", "Category Tag error. ");
  }
}

export async function getProcessCategoryTags() {
  let response;
  try {
    response = await http().get("/api/categoryTagsWithTags");

    return response.data;
  } catch (e) {
    showCatch(e, "Get Category Tag error. Response ", "Get Category Tag error. ");
  }
}

export async function downloadCandidatesProcess(processId: string, candidateIds: string[]) {
  let params = { candidateIds };
  let response;
  try {
    response = await http().post("/api/processes/" + processId + "/candidates/download", params);

    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function downloadAllCandidatesProcess(processId: string) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/downloadAll");
    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

/**
 * Function that upload the process data in the last position of list
 * @param {*} process process to upload
 */
export async function postProcess(process: any, createTemplate: boolean, isGlobal: boolean) {
  let response;
  try {
    response = await http().post("/api/processes", process, {
      params: { createTemplate, isGlobal },
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Post process error. Response ", "Post process error. ");
  }
}

export async function onlyCreateTemplate(process: any) {
  let response;
  try {
    response = await http(true).post("/api/processTemplate", process);
    return response.data;
  } catch (e) {
    showCatch(e, "Post process error. Response ", "Post process error. ");
  }
}

export async function postDraftProcess(process: any, logicSave?: boolean) {
  let url = `/api/processes/drafts${logicSave ? "?logicSave=true" : ""}`;
  let response;
  try {
    response = await http().post(url, process);
    return response.data;
  } catch (e) {
    showCatch(e, "Post process error. Response ", "Post process error. ");
  }
}

export async function getAttachment(attachmentId: string) {
  let response;
  try {
    response = await http().get("/api/processes/attachment/" + attachmentId);
    return response.data;
  } catch (e) {
    showCatch(e, "Get attachment error. Response ", "Get attachment error. ");
  }
}

export async function uploadAttachment(name: string, file: File, checksum: string) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", name);
  bodyFormData.append("file", file);
  bodyFormData.set("checksum", checksum);
  bodyFormData.set("mimeType", file.type);

  let response;
  try {
    response = await http().post("/api/processes/attachment", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload video. Response ", "Upload video. ");
  }
}

export async function deleteAttachment(attachmentId: string) {
  let response;
  try {
    response = await http().delete("/api/processes/attachment/" + attachmentId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete attachment error. Response ", "Delete attachment error. ");
  }
}
/**
 * Function that upload the process data in the last position of list
 * @param {*} process process to upload
 */
export async function uploadCandidateList(processId: string, candidates: PublicCandidate[]) {
  let response;
  const { selectedTenant } = store.getState().tenant;
  try {
    if (selectedTenant.id === CUSTOM_TENANTS.USA) response = await http().post("/api/processes/" + processId + "/candidatesBulkCA", { candidates: candidates });
    else response = await http().post("/api/processes/" + processId + "/candidatesBulk", { candidates: candidates });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload candidate list error. Response ", "Upload candidate list error. ");
  }
}

export async function transferAllCandidates(fromProcessId: string, toProcessId: string, allFilteredCandidatesIds: string[]) {
  let response;
  try {
    response = await http().post("/api/processes/" + fromProcessId + "/transferAll/" + toProcessId, { candidatesIds: allFilteredCandidatesIds });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Transfer candidate list error. Response ", "Transfer candidate list error. ");
  }
}

export async function uploadPersonServices(serviceId: string, persons: any[]) {
  let response;
  try {
    response = await http().post("/api/services/" + serviceId + "/assessments", { persons });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload candidate service error. Response ", "Upload candidate service error. ");
  }
}

export async function fetchSkillList(filter: string) {
  const params = { filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/skills", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill list error. Response ", "Skill list error. ");
  }
}

export async function fetchAssessmentPhaseSkillList(type: number) {
  const params = { type };
  let response;
  try {
    response = await http().get("/api/skills/assessmentPhase", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill list error. Response ", "Skill list error. ");
  }
}

export async function fetchAssessmentPhaseTypesList(type: number) {
  const params = { type };
  let response;
  try {
    response = await http().get("/api/assessmentTypeXpertGlobalTest", {
      params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill list error. Response ", "Skill list error. ");
  }
}

export async function fetchGlobalSkillList(filter: string) {
  const params = { filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/skillsGlobal", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill list error. Response ", "Skill list error. ");
  }
}

export async function uploadGlobalSkill(name: string, info: any) {
  let response;
  try {
    response = await http().post("/api/skillsGlobal", {
      name: name,
      info: info,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function updateGlobalSkill(skill: Skill) {
  let response;
  try {
    response = await http().put("/api/skillsGlobal/" + skill.id, {
      name: skill.name,
      info: skill.info,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function deleteGlobalSkill(skillId: string) {
  let response;
  try {
    response = await http().delete("/api/skillsGlobal/" + skillId);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function fetchAdminSkillList(filter: string, onlyMissingGlobals?: boolean) {
  const params = { onlyMissingGlobals, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/skillsAdmin", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill list error. Response ", "Skill list error. ");
  }
}

export async function uploadAdminSkill(name: string, info: any, localeName: string, localeInfo: any, globalId: string) {
  let response;
  try {
    response = await http().post("/api/skillsAdmin", {
      name: name,
      info: info,
      localeName: localeName,
      localeInfo: localeInfo,
      globalId: globalId,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function updateAdminSkill(skill: Skill) {
  let response;
  try {
    response = await http().put("/api/skillsAdmin/" + skill.id, {
      name: skill.name,
      info: skill.info,
      localeName: skill.localeName,
      localeInfo: skill.localeInfo,
      globalId: skill.globalId,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function deleteAdminSkill(skillId: string) {
  let response;
  try {
    response = await http().delete("/api/skillsAdmin/" + skillId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete skill. Response ", "Delete skill. ");
  }
}

export async function fetchAllScreeningQuestionTypes(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/screeningQuestionTypes", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function uploadScreeningQuestionType(name: string, localName: string) {
  let response;
  try {
    response = await http().post("/api/screeningQuestionTypes", {
      name,
      localName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function updateScreeningQuestionType(id: string, name: string, localName: string) {
  let response;
  try {
    response = await http().put("/api/screeningQuestionTypes/" + id, {
      name,
      localName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function deleteScreeningQuestionType(id: string) {
  let response;
  try {
    response = await http().delete("/api/screeningQuestionTypes/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function fetchAllScreeningQuestionsLibrary(page: number, size: number, order: any, filter: string, external?: boolean, defaultLanguage?: string) {
  const params = { page, size, order, filter, external, defaultLanguage } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/screeningQuestionsLibrary", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function uploadScreeningQuestionLibrary(screeningQuestionLibrary: ScreeningQuestionLibrary) {
  let response;
  try {
    response = await http().post("/api/screeningQuestionsLibrary", {
      screeningQuestionLibrary,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function updateScreeningQuestionLibrary(screeningQuestionLibrary: any) {
  if (screeningQuestionLibrary.weight === null) {
    delete screeningQuestionLibrary.weight;
  }
  let response;
  try {
    response = await http().put("/api/screeningQuestionsLibrary/" + screeningQuestionLibrary.id, {
      screeningQuestionLibrary,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function deleteScreeningQuestionLibrary(screeningQuestionLibraryId: string) {
  let response;
  try {
    response = await http().delete("/api/screeningQuestionsLibrary/" + screeningQuestionLibraryId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function fetchAllFilteredPositions(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/positions", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function fetchAllPositions() {
  let response;
  try {
    response = await http().get("/api/positions/all");
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function uploadPosition(position: Position) {
  let response;
  try {
    response = await http().post("/api/positions", {
      position,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function updatePosition(position: Position) {
  let response;
  try {
    response = await http().put("/api/positions/" + position.id, {
      position,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function deletePosition(positionId: string) {
  let response;
  try {
    response = await http().delete("/api/positions/" + positionId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function fetchAllFilteredContractTypes(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/contractTypes", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch contractType error. Response ", "fetch contractType error. ");
  }
}

export async function fetchAllContractTypes() {
  let response;
  try {
    response = await http().get("/api/contractTypes/all");
    return response.data;
  } catch (e) {
    showCatch(e, "fetch position error. Response ", "fetch position error. ");
  }
}

export async function uploadContractType(contractType: ContractType) {
  let response;
  try {
    response = await http().post("/api/contractTypes", {
      contractType,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch contractType error. Response ", "fetch contractType error. ");
  }
}

export async function updateContractType(contractType: ContractType) {
  let response;
  try {
    response = await http().put("/api/contractTypes/" + contractType.id, {
      contractType,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch contractType error. Response ", "fetch contractType error. ");
  }
}

export async function deleteContractType(contractTypeId: string) {
  let response;
  try {
    response = await http().delete("/api/contractTypes/" + contractTypeId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch contractType error. Response ", "fetch contractType error. ");
  }
}

export async function fetchOfferTemplates() {
  let response;
  try {
    response = await http().get("/api/offerTemplate");
    return response.data;
  } catch (e) {
    showCatch(e, "fetch offerTemplate error. Response ", "fetch offerTemplate error. ");
  }
}

export async function uploadOfferTemplate(offerTemplate: OfferTemplate) {
  let response;
  try {
    response = await http().post("/api/offerTemplate", {
      offerTemplate,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch offerTemplate error. Response ", "fetch offerTemplate error. ");
  }
}

export async function updateOfferTemplate(offerTemplate: OfferTemplate) {
  let response;
  try {
    response = await http().put("/api/offerTemplate/" + offerTemplate.id, {
      offerTemplate,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch offerTemplate error. Response ", "fetch offerTemplate error. ");
  }
}

export async function deleteOfferTemplate(offerTemplateId: string) {
  let response;
  try {
    response = await http().delete("/api/offerTemplate/" + offerTemplateId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch offerTemplate error. Response ", "fetch offerTemplate error. ");
  }
}

export async function fetchAllReferenceCheckLibrary(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/referenceCheckLibrary", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch referenceCheckLibrary error. Response ", "fetch referenceCheckLibrary error. ");
  }
}

export async function uploadReferenceCheckLibrary(referenceCheckLibrary: ReferenceCheckLibrary) {
  let response;
  try {
    response = await http().post("/api/referenceCheckLibrary", {
      referenceCheckLibrary,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch referenceCheckLibrary error. Response ", "fetch referenceCheckLibrary error. ");
  }
}

export async function updateReferenceCheckLibrary(referenceCheckLibrary: ReferenceCheckLibrary) {
  let response;
  try {
    response = await http().put("/api/referenceCheckLibrary/" + referenceCheckLibrary.id, {
      referenceCheckLibrary,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch referenceCheckLibrary error. Response ", "fetch referenceCheckLibrary error. ");
  }
}

export async function deleteReferenceCheckLibraryLibrary(referenceCheckLibraryId: string) {
  let response;
  try {
    response = await http().delete("/api/referenceCheckLibrary/" + referenceCheckLibraryId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch referenceCheckLibrary error. Response ", "fetch referenceCheckLibrary error. ");
  }
}

export async function fetchAllVideoTypes(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/videoTypes", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function uploadVideoType(name: string, localeName: string) {
  let response;
  try {
    response = await http().post("/api/videoTypes", {
      name,
      localeName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function updateVideoType(id: string, name: string, localeName: string) {
  let response;
  try {
    response = await http().put("/api/videoTypes/" + id, {
      name,
      localeName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function deleteVideoType(id: string) {
  let response;
  try {
    response = await http().delete("/api/videoTypes/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function fetchSasToken(blobName: any) {
  let response;
  try {
    response = await http().get("/api/blobs/sasToken", blobName);
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch sas token error. Response ", "Fetch sas token error. ");
  }
}

export async function fetchAllVideoLibrary(page: number, size: number, order: any, filter: string, type: string, isPersonal: boolean) {
  const params = { page, size, order, filter, type, isPersonal } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") {
    delete params.filter;
  }
  if (params.type === null || params.type === undefined || params.type === "") {
    delete params.type;
  }
  let response;
  try {
    response = await http().get("/api/videoLibrary", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function uploadVideoLibrary(video: VideoLibraryI) {
  let response;
  try {
    response = await http().post("/api/videoLibrary", {
      video,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function updateVideoLibrary(id: string, video: VideoLibraryI) {
  let response;
  try {
    response = await http().put("/api/videoLibrary/" + id, {
      video,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function deleteVideoLibrary(id: string) {
  let response;
  try {
    response = await http().delete("/api/videoLibrary/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload skill. Response ", "Upload skill. ");
  }
}

export async function fetchCategory(categoryId: string) {
  let response;
  try {
    response = await http().get("/api/professions/" + categoryId);
    return response.data.professions;
  } catch (e) {
    showCatch(e, "Categories error. Response ", "Categories error. ");
  }
}
/**
 *
 * Function that search list of categories where the tenant is same
 *
 * @return {list}
 */
export async function fetchCategoriesList(filter?: string) {
  const params = { filter };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/professions", { params: params });
    return response.data.professions;
  } catch (e) {
    showCatch(e, "Categories error. Response ", "Categories error. ");
  }
}

export async function fetchCategoriesAdminList(filter?: string, onlyMissingGlobals?: boolean) {
  const params = { filter, onlyMissingGlobals };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/professionsAdmin", { params: params });
    return response.data.professions;
  } catch (e) {
    showCatch(e, "Categories error. Response ", "Categories error. ");
  }
}

export async function fetchCategoriesGlobalList(filter?: string, onlyMissingGlobals?: boolean) {
  const params = { filter, onlyMissingGlobals };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/professionsGlobal", { params: params });
    return response.data.professions;
  } catch (e) {
    showCatch(e, "Categories error. Response ", "Categories error. ");
  }
}

export async function uploadLocalCategory(category: Profession) {
  let response;
  if (category.id) delete category.id;

  try {
    response = await http().post("/api/professionsAdmin", category);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload category. Response ", "Upload category. ");
  }
}

export async function uploadGlobalCategory(name: string, industryId: string) {
  let response;
  try {
    response = await http().post("/api/professionsGlobal", {
      name: name,
      industryId: industryId,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload category. Response ", "Upload category. ");
  }
}

export async function updateLocalCategory(category: Profession) {
  let response;
  try {
    response = await http().put("/api/professionsAdmin/" + category.id, {
      name: category.name,
      globalId: category.globalId,
      localeName: category.localeName,
      industryId: category.industryId,
      kernelId: category.kernelId,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload category. Response ", "Upload category. ");
  }
}

export async function updateGlobalCategory(category: Profession) {
  let response;
  try {
    response = await http().put("/api/professionsGlobal/" + category.id, {
      name: category.name,
      industryId: category.industryId,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload category. Response ", "Upload category. ");
  }
}

export async function fetchCobrand(page: number, size: number, order: string, filter?: string) {
  let response;
  const params = { page, size, order, filter };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  try {
    response = await http().get("/api/cobrand", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get Cobrand error. Response ", "Get cobrand error. ");
  }
}

export async function postCobrand(payload: any, imageFile: any) {
  let bodyFormData = new FormData();
  bodyFormData.set("name", payload?.name);
  if (payload?.officialId) bodyFormData.set("officialId", payload?.officialId);
  bodyFormData.set("officialName", payload?.officialName);
  bodyFormData.set("externalCode", payload?.externalCode);
  bodyFormData.set("code", payload?.code);
  bodyFormData.set("emailColor", payload?.emailColor);
  if (payload?.emailImage) bodyFormData.set("emailImage", payload?.emailImage);
  bodyFormData = appendFileData(bodyFormData, imageFile, "asd", "emailHeaderImage");
  bodyFormData.set("type", imageFile.type);
  if (payload?.cammioBrandingId) bodyFormData.set("cammioBrandingId", JSON.stringify(payload?.cammioBrandingId));
  if (payload?.variables) bodyFormData.set("variables", JSON.stringify(payload?.variables));
  payload.tenants?.forEach((item: any) => bodyFormData.append("tenants", item));
  if (payload?.brand) bodyFormData.set("brand", JSON.stringify(payload?.brand));
  if (payload?.client) bodyFormData.set("client", JSON.stringify(payload?.client));
  let response;
  try {
    response = await http().post("/api/cobrand", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Cobrand error. Response ", "Post Cobrand error. ");
  }
}

export async function putCobrand(id: string, payload: any, imageFile: any) {
  let bodyFormData = new FormData();
  bodyFormData.set("name", payload?.name);
  if (payload?.officialId) bodyFormData.set("officialId", payload?.officialId);
  bodyFormData.set("officialName", payload?.officialName);
  bodyFormData.set("externalCode", payload?.externalCode);
  bodyFormData.set("code", payload?.code);
  bodyFormData.set("emailColor", payload?.emailColor);
  if (payload?.emailImage) bodyFormData.set("emailImage", payload?.emailImage);
  bodyFormData = appendFileData(bodyFormData, imageFile, "asd", "emailHeaderImage");
  bodyFormData.set("type", imageFile.type);
  if (payload?.cammioBrandingId) bodyFormData.set("cammioBrandingId{}", payload?.cammioBrandingId);
  if (payload?.variables) bodyFormData.set("variables{}", payload?.variables);
  payload.tenants?.forEach((item: any) => bodyFormData.append("tenants[]", item));
  if (payload?.brand) bodyFormData.set("brand{}", payload?.brand);
  if (payload?.client) bodyFormData.set("client{}", payload?.client);

  let response;
  try {
    response = await http().put(`/api/cobrand/${id}`, bodyFormData);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Cobrand error. Response ", "Update Cobrand error. ");
  }
}

export async function deleteCobrand(id: string) {
  let response;
  try {
    response = await http().delete(`/api/cobrand/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete Cobrand error. Response ", "Delete Cobrand error. ");
  }
}

export async function fetchTenantCobrand(page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  try {
    response = await http().get("/api/tenantCobrand", { params });

    return response.data;
  } catch (e) {
    showCatch(e, "Get Tenant Cobrand error. Response ", "Get Tenant Cobrand Attribute error. ");
  }
}

export async function putTenantCobrand(id: string, params: any) {
  let response;
  try {
    response = await http().put(`/api/tenantCobrand/${id}`, params);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Tenant Cobrand error. Response ", "Update Tenant Cobrand Attribute error. ");
  }
}

export async function fetchTemplateListAll(processTemplateFilter?: string) {
  let response;
  const params = { processTemplateFilter };
  try {
    response = await http().get("/api/templates/list", { params });
    return response.data.templates;
  } catch (e) {
    showCatch(e, "Templates error. Response ", "Templates error. ");
  }
}

export async function fetchTemplateList(categoryId: string) {
  let response;
  try {
    response = await http().get("/api/professions/" + categoryId + "/templates");
    return response.data.templates;
  } catch (e) {
    showCatch(e, "Templates error. Response ", "Templates error. ");
  }
}

export async function fetchTemplate(categoryId: string, templateId: string) {
  let response;
  try {
    response = await http().get("/api/professions/" + categoryId + "/templates/" + templateId);
    return response.data;
  } catch (e) {
    showCatch(e, "Template error. Response ", "Template error. ");
  }
}

export async function fetchNumCandidates(processId: string) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/num");
    return response.data;
  } catch (e) {
    showCatch(e, "Process error. Response ", "Process error. ");
  }
}

/**
 *
 * Funtion that search a process
 *
 * @param {string} processId process id
 * @return {process}
 */

export async function fetchProcess(processId: string, firstLoad: boolean = false, externalId?: string) {
  let url = "/api/processes/" + processId;
  if (firstLoad) url = url + "?firstLoad=true";
  if (externalId) url = url + `${firstLoad ? "&" : "?"}externalId=${externalId}`;
  let response;
  try {
    response = await http().get(url);
    return response.data;
  } catch (e) {
    showCatch(e, "Process error. Response ", "Process error. ");
  }
}

/**
 *
 * Funtion that search a candidate in the process in which this
 *
 * @param {number} id process id where candidate are
 * @param {*} candidateId candidate id go find
 * @return {candidate}
 */
export async function fetchCandidate(processId: string, candidateId: any) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/" + candidateId);
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate error. Response ", "Candidate error. ");
  }
}

export async function fetchCandidateLiveInterview(processId: string, candidateId: string, liveInterviewId: string) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/" + candidateId + "/" + liveInterviewId);
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate error. Response ", "Candidate error. ");
  }
}

export async function updateCandidateExtraLiveInterview(processId: string, candidateId: string, liveInterviewId: string, data: any) {
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/" + liveInterviewId, data);
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate error. Response ", "Candidate error. ");
  }
}

/**
 *
 * Funtion that returns a candidate for reviewing of a given process
 *
 * @param {string} id process id where candidate are
 * @param {stringº} candidateId candidate id go find
 * @return {candidate}
 */
export async function fetchCandidateForReview(processId: string, candidateId: any) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/candidates/" + candidateId + "/review");
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Review error. Response ", "Candidate Review error. ");
  }
}

export async function fetchPublicProcess(processId: string, tenantAlias?: string) {
  let response;
  const headers = tenantAlias ? { "X-Tenant-Alias": tenantAlias } : {};
  try {
    response = await http(false, headers).get("/api/processes/" + processId + "/public");
    return response.data;
  } catch (e) {
    showCatch(e, "Process error. Response ", "Process error. ");
  }
}
export async function fetchPublicJobDescriptionProcess(jdId: string, tenantAlias?: string) {
  let response;
  const headers = tenantAlias ? { "X-Tenant-Alias": tenantAlias } : {};
  try {
    response = await http(false, headers).get("/sharedApi/jobDescriptionProcesses/" + jdId + "/public");
    return response.data;
  } catch (e) {
    showCatch(e, "Process error. Response ", "Process error. ");
  }
}
export async function fetchPublicJobDescriptionProcessTheme(jdId: string, tenantAlias?: string) {
  let response;
  const headers = tenantAlias ? { "X-Tenant-Alias": tenantAlias } : {};
  try {
    response = await http(false, headers).get("/sharedApi/jobDescriptionProcesses/" + jdId + "/public/theme");
    return response.data;
  } catch (e) {
    showCatch(e, "Process error. Response ", "Process error. ");
  }
}

/**
 *
 * Function that updates the candidate in the process in which this
 *
 * @param {number} processId process id where candidate are
 * @param {*} candidate update candidate
 * @return {updateCandidate}
 */
export async function updateCandidate(
  processId: string,
  candidate: Candidate,
  phaseIndex: number,
  language?: string,
  version?: string,
  formData?: Array<FormInterface>,
  termsId?: string,
  _terms: boolean = true
) {
  // Remove parameter ever is true and not send "terms"
  const candidateId = candidate.id;
  const phaseResult = candidate.phasesResults[phaseIndex];
  const phaseChange = candidate.phaseChange;
  const cv = candidate.cv;
  const additionalDocuments = candidate.additionalDocuments;
  //the response doesn't have files, update backend if needed
  const params = {
    phaseIndex: phaseIndex,
    phaseResult: phaseResult,
    phaseChange: phaseChange,
    formData: formData,
    termId: termsId,
    termLanguage: language,
    termVersion: version,
    cv: cv,
    additionalDocuments: additionalDocuments,
    language: candidate.language,
  };
  if (params.termId === null || params.termId === undefined || params.termId === "") delete params.termId;
  if (params.termLanguage === null || params.termLanguage === undefined || params.termLanguage === "") delete params.termLanguage;
  if (params.termVersion === null || params.termVersion === undefined || params.termVersion === "") delete params.termVersion;

  if (params.phaseChange === null || params.phaseChange === undefined) delete params.phaseChange;

  if (params.cv === null || params.cv === undefined || params.cv === "") delete params.cv;
  if (params.additionalDocuments === null || params.additionalDocuments === undefined || params.additionalDocuments.some((doc) => !doc.hasOwnProperty("file"))) {
    delete params.additionalDocuments;
  }
  if (params.language === null || params.language === undefined) delete params.language;

  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/phasesResults", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate. Response ", "Update candidate. ");
  }
}

export async function updateTerms(processId: string, candidateId: string, termsChecked: boolean, termId: string, termLang: string, termVersion: string) {
  let params = {
    isTermsChecked: termsChecked,
    termId: termId,
    termLanguage: termLang,
    termVersion: termVersion,
  };
  try {
    return await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/updateTerms", params);
  } catch (e) {
    showCatch(e, "Update candidate Terms. Response ", "Update candidate Terms. ");
  }
}

export async function uploadPublicCandidate(processId: string, candidate: PublicCandidate) {
  let response;
  const path = "/api/processes/" + processId + "/candidates";
  try {
    response = await http().post(path, candidate);
    return response.data;
  } catch (e) {
    let message;
    if ((e as any).isAxiosError) {
      const ae = e as AxiosError;
      const errorMessage = checkForAccessErrors(ae);
      if (errorMessage !== "") {
        message = errorMessage;
      } else {
        const responseStatusText = ae && ae.response ? ae.response.statusText : "STATUS_TEXT_UNKNOWN";
        const responseStatus = ae && ae.response ? ae.response.status : "STATUS_UNKNOWN: " + responseStatusText;
        let aError = ae.response?.data.data?.validationErrors[0].path[0];
        let typeError = ae.response?.data.errorName;
        if (aError) message = "Post new candidate error. Response " + responseStatus + "/" + typeError + "/" + aError;
        else message = "Post new candidate error. Response " + responseStatus + "/" + typeError;
      }
    } else {
      message = "Post new candidate error. " + (e as any).message;
    }
    console.error(message, e);
    throw new Error(message);
  }
}

export async function fetchVideoQuestionsLibrary(page: number, size: number, order: string, filter: any, defaultLanguage?: string) {
  const params = { page, size, order, filter, defaultLanguage };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/videoQuestionsLibrary", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill questions error. Response ", "Skill questions error. ");
  }
}

export async function uploadVideoQuestionsLibrary(skillId: string, localeQuestion: string, questionId: string, multilanguage: Record<string, any>) {
  let response;
  try {
    response = await http().post("/api/videoQuestionsLibrary", {
      skillId,
      localeQuestion,
      questionId,
      multilanguage,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill questions error. Response ", "Skill questions error. ");
  }
}

export async function updateVideoQuestionsLibrary(skillId: string, localeQuestion: string, questionId: string, multilanguage: Record<string, any>) {
  let response;
  try {
    response = await http().put("/api/videoQuestionsLibrary/" + questionId, {
      skillId,
      localeQuestion,
      questionId,
      multilanguage,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Skill questions error. Response ", "Skill questions error. ");
  }
}

export async function deleteVideoQuestionsLibrary(questionId: string) {
  let response;
  try {
    response = await http().delete("/api/videoQuestionsLibrary/" + questionId);
    return response.data;
  } catch (e) {
    showCatch(e, "Skill questions error. Response ", "Skill questions error. ");
  }
}

export async function fetchGlobalVideoQuestions() {
  let response;
  try {
    response = await http().get("/api/videoQuestionsLibrary/global");
    return response.data;
  } catch (e) {
    showCatch(e, "Skill questions error. Response ", "Skill questions error. ");
  }
}

export async function fetchSkill(skillId: string) {
  let response;
  try {
    response = await http().get("/api/skills/" + skillId);
    return response.data;
  } catch (e) {
    showCatch(e, "Skill error. Response ", "Skill error. ");
  }
}

/**
 *
 * Function that updates the candidate in the process in which this
 *
 * @param {number} processId process id where candidate are
 * @param {*} candidate update candidate
 * @return {updateCandidate}
 */
export async function reviewCandidate(processId: string, candidateId: string, phaseIndex: number, phaseReview: ProcessPhase, isFailed?: boolean, sendDismissedNotification?: boolean) {
  let response;

  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/phaseReview", {
      phaseIndex: phaseIndex,
      phaseReview: phaseReview,
      isFailed: isFailed,
      sendDismissedNotification: sendDismissedNotification,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Review candidate. Response ", "Review candidate. ");
  }
}

export async function reviewCandidateExtraLiveInterview(processId: string, candidateId: string, liveInterview: LiveInterviewExtra) {
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/phaseExtraLiveInterviewReview", {
      liveInterview: liveInterview,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Review candidate. Response ", "Review candidate. ");
  }
}

/**
 *
 * Function that fetches the terms and conditions
 *
 * @param {number} processId process id where candidate are
 * @param {*} candidate update candidate
 * @return {updateCandidate}
 */
export async function fetchTerms() {
  let response;
  try {
    response = await http().get("/api/terms?type=term");
    return response.data;
  } catch (e) {
    showCatch(e, "Terms response. Response ", "Terms response. ");
  }
}

export async function postTerms(html: any, externalUrl: any) {
  let response;
  try {
    response = await http().post("/api/terms?type=term", { html: html, externalUrl });
    return response.data;
  } catch (e) {
    showCatch(e, "Terms error. Response ", "Terms error. ");
  }
}

/**
 * Function that fetches the privacy policy
 */
export async function fetchPrivacyPolicy() {
  let response;
  try {
    response = await http().get("/api/terms?type=policy");
    return response.data;
  } catch (e) {
    showCatch(e, "Policies response. Response ", "Policies response. ");
  }
}

export async function postPolicies(html: any, externalUrl: any) {
  let response;
  try {
    response = await http().post("/api/terms?type=policy", { html, externalUrl });
    return response.data;
  } catch (e) {
    showCatch(e, "Policies error. Response  ", "Policies error. ");
  }
}
/**
 * Function that fetches the marketing consent
 */
export async function fetchMarketingConsent() {
  let response;
  try {
    response = await http().get("/api/terms?type=marketing");
    return response.data;
  } catch (e) {
    showCatch(e, "Policies response. Response ", "Policies response. ");
  }
}

export async function postMarketing(html: any, externalUrl: any) {
  let response;
  try {
    response = await http().post("/api/terms?type=marketing", { html, externalUrl });
    return response.data;
  } catch (e) {
    showCatch(e, "Policies error. Response  ", "Policies error. ");
  }
}

export async function fetchDisclaimerMessage() {
  let response;
  try {
    response = await http().get("/api/terms?type=recorded-video-interview-disclaimer");
    return response.data;
  } catch (e) {
    showCatch(e, "Disclaimer message error response. Response ", "Disclaimer message error response. ");
  }
}

export async function postDisclaimerMessage(html: any) {
  let response;
  try {
    response = await http().post("/api/terms?type=recorded-video-interview-disclaimer", { html: html });
    return response.data;
  } catch (e) {
    showCatch(e, "Policies error. Response  ", "Policies error. ");
  }
}

export async function fetchVideoInterviewBrandings() {
  let response;
  try {
    response = await http().get("/api/videoInterview/brandings");
    return response.data;
  } catch (e) {
    showCatch(e, "Default video interview error. Response  ", "Default video interview error. ");
  }
}

export async function fetchVideoInterviewDefaultConfig() {
  let response;
  try {
    response = await http().get("/api/videoInterview/config");
    return response.data;
  } catch (e) {
    showCatch(e, "Default video interview error. Response  ", "Default video interview error. ");
  }
}

export async function updateVideoInterviewDefaultConfig(settings: CammioSettings) {
  let response;
  if (settings.brandingId === null || settings.brandingId === undefined) delete settings.brandingId;
  try {
    response = await http().post("/api/videoInterview/config", settings);
    return response.data;
  } catch (e) {
    showCatch(e, "Default video interview error. Response  ", "Default video interview error. ");
  }
}

export async function fetchTenantAndUserInfo() {
  let response;
  try {
    response = await http().get("/api/me");
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant info error. Response ", "Tenant info interview error. ");
  }
}
// not tested
export async function fetchThumbnail(fetchUrl: string) {
  try {
    let headers = {
      "X-Tenant-Alias": store.getState().tenant.selectedTenant.alias,
      "Ocp-Apim-Subscription-Key": APIM_KEY !== undefined ? APIM_KEY : "",
    };

    return await fetch(fetchUrl, {
      headers: headers,
    });
  } catch (e) {
    let message = "Fetch Video Thumbnail error. " + (e as any).message;
    console.error(message, e);
    throw new Error(message);
  }
}

export async function fetchStatsForAdmintrator(tenants: string[], initDate: string, endDate: string, format: string) {
  let response;
  try {
    response = await http().put("/api/stats?initDate=" + initDate + "&endDate=" + endDate + "&type=" + format, { tenants });
    return response.data;
  } catch (e) {
    showCatch(e, "Stats response. Response  ", "Stats response. ");
  }
}

export async function fetchTenants(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/tenants/admin", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch tenants admin error. Response ", "fetch tenants admin error. ");
  }
}

export async function uploadTenant(tenant: TenantsAdmin) {
  let response;
  try {
    response = await http().post("api/tenants", tenant);
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload tenants list error. Response ", "Upload tenants list error. ");
  }
}

export async function updateTenant(editTenant: TenantsAdmin) {
  let response;
  try {
    response = await http().put("/api/tenants/admin/" + editTenant.id, editTenant);
    return response.data;
  } catch (e) {
    showCatch(e, "Update admin tenants. Response ", "Update admin tenants. ");
  }
}

export async function postCategory(category: Profession) {
  let response;
  try {
    response = await http().post("/api/professions", {
      name: category.name,
      localName: category.localeName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Category error. Response ", "Category error. ");
  }
}

export async function deleteTemplate(templateId: string) {
  let response;
  try {
    response = await http(true).delete("/api/templates/" + templateId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete template. Response ", "Delete template. ");
  }
}

export async function deleteAdminCategory(categoryId: string) {
  let response;
  try {
    response = await http().delete("/api/professionsAdmin/" + categoryId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete category. Response ", "Delete category. ");
  }
}

export async function deleteGlobalCategory(categoryId: string) {
  let response;
  try {
    response = await http().delete("/api/professionsGlobal/" + categoryId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete category. Response ", "Delete category. ");
  }
}

export async function fetchTenantUsers(page: number, size: number, order: string, filter: string, role: string) {
  const params = { page, size, order, filter, role } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  if (params.role === null || params.role === undefined || params.role === "") delete params.role;
  let response;
  try {
    response = await http().get("/api/users", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant users error. Response ", "Tenant users error. ");
  }
}

export async function fetchAllTenantUsers() {
  let response;
  try {
    response = await http().get("/api/users/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant all users error. Response ", "Tenant all users error. ");
  }
}

export async function insertTenantUser(user: TenantSendUser) {
  let response;
  try {
    response = await http().post("/api/users", user);
    return response.data;
  } catch (e) {
    showCatch(e, "Post tenant user error. Response ", "Post tenant user error. ");
  }
}

export async function updateTenantUser(user: TenantSendUser) {
  let response;
  try {
    response = await http().put("/api/users/" + user.id, user);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tenant user error. Response ", "Put tenant user error. ");
  }
}

export async function removeTenantUser(userId: string) {
  let response;
  try {
    response = await http().delete("/api/users/" + userId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete tenant user error. Response ", "Delete tenant user error. ");
  }
}

export async function getAllLanguages() {
  let response;
  try {
    const tenantAlias = store.getState().tenant?.selectedTenant?.alias;
    response = await http().get(`/api/tenants/${tenantAlias}/langs`);
    return response.data;
  } catch (e) {
    showCatch(e, "Error loading tenant languages", "Error loading tenant languages. ");
  }
}

export async function updateLanguagesForTenant(languages: string[]) {
  let response;
  try {
    response = await http().put("/api/tenants/" + store.getState().tenant.selectedTenant.alias, { languages: languages });
    return response.data;
  } catch (e) {
    showCatch(e, "Add languages tenant. Response ", "Add languages tenant. ");
  }
}

export function checkForAccessErrors(ae: AxiosError): string {
  if (ae?.response === undefined) return "";
  if (ae?.response?.data?.errorName !== null && ae?.response?.data?.errorName !== undefined) {
    const errorName = ae?.response?.data?.errorName;
    const errorMessage = ae?.response?.data?.message;
    const innerError = ae?.response?.data?.frameworkError;

    const typeError = ae.response;
    switch (errorName) {
      case "AuthMultipleErrorsError":
        if (ae.response.data.data !== null && ae.response.data.data.errors !== null && ae.response.data.data.errors.length > 0) {
          let newError: string = "AuthMultipleErrorsError";
          ae.response.data.data.errors.forEach((error: any) => {
            if (
              (error.name !== undefined && newError !== "AuthTokenExpiredError" && newError !== "AuthTokenUserMissingError") ||
              (error.name !== undefined && newError === "AuthTokenUserMissingError" && error.name === "AuthTokenExpiredError")
            ) {
              newError = error.name;
            }
          });
          return newError;
        } else {
          return errorName;
        }
      case "AuthHeaderNoBearerError":
      case "AuthTokenUndecodablePayloadError":
      case "AuthTokenNoIssuerError":
      case "AuthTokenExpiredError":
      case "AuthTokenUnknownIssuerError":
      case "AuthTokenSubMissingError":
      case "AuthTokenUserMissingError":
      case "ProcessClosedOrExpired":
      case "ProcessPlannedOrDraft":
      case "ProcessRemoved":
      case "EntityIdExist":
      case "PhaseNotFailed":
      case "CandidateExist":
      case "EntityInUse":
      case "QueryFailedError":
      case "EntityNotFoundError":
      case "ProcessExpiredOrClosed":
      case "PhaseResultStateTransactionError":
      case "RecruiterSlotOccupied":
      case "CVIsMandotory":
      case "MaxUserTemplates":
      case "ExternalIdDuplicated":
      case "ConversationUpdate":
      case "CampaigneUpdate":
      case "OutreachCreation":
        return innerError || errorName;
      case "Error":
        return errorMessage;
      case "DataValidationError":
        return errorName + ", " + typeError.data.frameworkError;
      case "TermsNotAccepted":
      case "PhaseNotFoundError":
        return errorName + "," + ae.response.data.data.redirectURL;
      default:
        return "";
    }
  } else if (ae?.response?.data?.statusCode !== null && ae?.response?.data?.statusCode !== undefined) {
    if (parseInt(ae.response.data.statusCode) === 401) return "AuthTokenUserMissingError";
    else return "";
  } else {
    return "";
  }
}
// not tested
const errorIsForeignConstraintError = (e: any): boolean =>
  e?.isAxiosError && e.response?.data?.errorName === "QueryFailedError" && e.response?.data?.message.includes("violates foreign key constraint");

export function checkForUploadError(ae: AxiosError): string {
  if (ae.response === undefined) return "";
  if (ae.response.data.message !== null && ae.response.data.message !== undefined) {
    const message: string = ae.response.data.message;
    if (message.includes("UNQ_Candidate_tenantId_processId_email")) {
      return "DUPL_EMAIL";
    } else {
      return "DEFAULT";
    }
  } else {
    return "";
  }
}

export async function getCsvSkills() {
  try {
    await http()
      .get("/api/skillsCSV", {
        responseType: "blob",
      })
      .then((response) => {
        saveAs(response.data, "skillsCSV.csv");
      });
  } catch (e) {
    showCatch(e, "Csv download error. Response ", "Tenant users error. ");
  }
}

export async function getCsvProfessions() {
  try {
    await http()
      .get("/api/professionsCSV", {
        responseType: "blob",
      })
      .then((response) => {
        saveAs(response.data, "professionsCSV.csv");
      });
  } catch (e) {
    showCatch(e, "Csv download error. Response ", "Tenant users error. ");
  }
}

export async function postUploadedTemplate(readedTemplate: any) {
  let response;
  try {
    response = await http().post("api/templates", readedTemplate);
    return response.data;
  } catch (e) {
    showCatch(e, "Post uploaded template error. Response ", "Post uploaded template error. ");
  }
}

export async function fetchTemplates(page: number, size: number, order: string, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/templates", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch templates error. Response ", "Fetch templates error. ");
  }
}
export async function fetchUnpaginatedTemplates() {
  const params = { unPaginated: true };
  let response;
  try {
    response = await http().get("/api/templates", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch templates error. Response ", "Fetch templates error. ");
  }
}

export async function fetchActivityLogs(filters: FiltersResults) {
  const { page, perPage, search, sortBy, sortDirection } = filters;
  const params = {
    page,
    perPage,
    search,
    sortBy,
    sortDirection,
  };
  try {
    return await http().get("/api/activityLogs", { params: params });
  } catch (e) {
    showCatch(e, "fetch activity logs error. Response ", "fetch activity logs error. ");
  }
}

export async function deleteProcess(processId: string) {
  let response;
  try {
    response = await http().delete("/api/processes/" + processId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete process error. Response ", "Delete process error. ");
  }
}

export async function dbPing() {
  let response;
  try {
    response = await http().get("/api/common/dbPing");
    return response.data;
  } catch (e) {
    showCatch(e, "DbPing error. Response ", "DbPing error. ");
  }
}

export async function aamBackendBlob() {
  let response;
  try {
    response = await http().get("/api/common/aamBackendBlob");
    return response.data;
  } catch (e) {
    showCatch(e, "AamBackendBlob error. Response ", "AamBackendBlob error. ");
  }
}

export async function updateTemplate(templateForUpdate: SendUpdateTemplate) {
  let response;
  try {
    response = await http(true).put("/api/templates/" + templateForUpdate.id, templateForUpdate);
    return response.data;
  } catch (e) {
    showCatch(e, "Put template error. Response ", "Put template error. ");
  }
}

export async function fetchAllIndustriesAdmin(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/industries", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch industries error. Response ", "fetch industries error. ");
  }
}

export async function fetchMailTemplates() {
  let response;
  try {
    response = await http().get("/api/mailTemplates");
    return response.data;
  } catch (e) {
    showCatch(e, "fetch mailTemplates error. Response ", "fetch mailTemplates error. ");
  }
}

export async function fetchMailJetList(filter: string, order: string) {
  const params = { order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/mailTemplatesMailjet", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch mailJet List error. Response ", "fetch mailJet List error. ");
  }
}

export async function postMailJetTemplate(mailJetTemplate: any) {
  let response;
  try {
    response = await http().post("api/mailTemplatesMailjet", mailJetTemplate);

    return response.data;
  } catch (e) {
    showCatch(e, "Post mailjet template error. Response ", "Post mailjet template error. ");
  }
}

export async function updateMailJetTemplate(mailjetId: string, mailjet: any) {
  let response;
  try {
    response = await http().put("/api/mailTemplatesMailjet/" + mailjetId, mailjet);
    return response.data;
  } catch (e) {
    showCatch(e, "Post mailjet error. Response ", "Post mailjet error. ");
  }
}

export async function deleteMailJet(mailjetId: any) {
  let response;
  try {
    response = await http().delete("/api/mailTemplatesMailjet/" + mailjetId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete mailjet templates error. Response ", "Delete mailjet templates error. ");
  }
}

export async function postMailTemplate(mailJetTemplate: any) {
  let response;
  try {
    response = await http().post("api/mailTemplates", mailJetTemplate);

    return response.data;
  } catch (e) {
    showCatch(e, "Post mailjet template error. Response ", "Post mailjet template error. ");
  }
}

export async function deleteMailTemplate(mailTemplateId: any) {
  let response;
  try {
    response = await http().delete("/api/mailTemplates/" + mailTemplateId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete mail template error. Response ", "Delete mail template error. ");
  }
}

export async function uploadIndustry(payload: SendUpsertIndustry) {
  const params = payload as any;
  let response;
  if (params.localeName === undefined || params.localeName === null || params.localeName === "") delete params.localeName;

  try {
    response = await http().post("/api/industriesAdmin", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put industry error. Response ", "Put industry error. ");
  }
}

export async function updateIndustry(industryId: string, payload: SendUpsertIndustry) {
  const params = payload as any;
  let response;
  if (params.localeName === undefined || params.localeName === null || params.localeName === "") delete params.localeName;
  try {
    response = await http().put("/api/industriesAdmin/" + industryId, params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put industry error. Response ", "Put industry error. ");
  }
}

export async function deleteIndustry(industryId: string) {
  let response;
  try {
    response = await http().delete("/api/industriesAdmin/" + industryId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete industry error. Response ", "Delete industry error. ");
  }
}

export async function fetchFeatureConfigurations(tenantAlias?: string) {
  let response;
  try {
    const headers = tenantAlias ? { "X-Tenant-Alias": tenantAlias } : {};
    response = await http(false, headers).get("/api/featureConfigurations");
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch features configuration error. Response ", "Fetch features configuration error. ");
  }
}
export async function fetchFeatureConfigurationBranchEnable(tenantAlias: string) {
  let response;
  try {
    const headers = tenantAlias ? { "X-Tenant-Alias": tenantAlias } : {};
    response = await http(false, headers).get("/api/featureConfigurations/branchEnabled");
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch features configuration error. Response ", "Fetch features configuration error. ");
  }
}

export async function fetchServiceAssesments(serviceId: string, personId: string) {
  let response;
  try {
    response = await http().get("/api/services/" + serviceId + "/assessments/" + personId + "/public");
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch features configuration error. Response ", "Fetch features configuration error. ");
  }
}

export async function updateFeatureConfigurations(features: any, isImport: boolean) {
  const params = { features, isImport };

  let response;
  try {
    response = await http().post("/api/featureConfigurations", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Update feature configuration error. Response ", "Update feature configuration error. ");
  }
}

export async function inviteCandidateInterview(processId: string, candidateId: string, phaseIndex: number, phasePrepare: any) {
  const params = {
    phaseIndex: phaseIndex,
    phasePrepare: phasePrepare,
  };
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/phasePrepare", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Update live interview phase error. Response ", "Update live interview phase error. ");
  }
}

export async function rescheduleCandidateLiveInterview(processId: string, candidateId: string, phaseIndex: number, phasePrepare: any) {
  const params = {
    phaseIndex: phaseIndex,
    phasePrepare: phasePrepare,
  };
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/reschedule", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Update live interview phase error. Response ", "Update live interview phase error. ");
  }
}

export async function inviteCandidateLiveInterviewPhase(processId: string, candidateId: any[], phasePrepare: any) {
  let response;
  const params = {
    ids: candidateId,
    phasePrepare: phasePrepare,
  };
  try {
    response = await http().post("/api/processes/" + processId + "/candidates/invite", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Add live interview phase error. Response ", "Add live interview phase error. ");
  }
}

export async function rescheduleCandidateExtraLiveInterviewPhase(processId: string, candidateId: string, liveInterviewId: string, phasePrepare: any) {
  let response;
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/" + candidateId + "/reschedule/" + liveInterviewId, {
      phasePrepare,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Reschedule extra live interview phase error. Response ", "Reschedule extra live interview phase error. ");
  }
}

export async function getCammioInterviewUrl(id: string) {
  let response;
  try {
    response = await http().get("/api/videoInterview/live/" + id);
    return response.data.CAMMIOUrl;
  } catch (e) {
    showCatch(e, "Cammio url interview error. Response ", "Cammio url interview error. ");
  }
}

export async function getServiceAssessmentInterviewCammioUrl(id: string) {
  let response;
  try {
    response = await http().get("/api/services/assessments/interview/" + id);
    return response.data.CAMMIOUrl;
  } catch (e) {
    showCatch(e, "Cammio url interview error. Response ", "Cammio url interview error. ");
  }
}

export async function getDefaultRecruiterCalendar() {
  let response;
  try {
    response = await http().get("/api/availability");
    return response.data;
  } catch (e) {
    showCatch(e, "Default calendar error. Response ", "Default calendar error. ");
  }
}

export async function postDefaultCalendar(defaulCalendar: any) {
  let response;
  try {
    response = await http().post("/api/availability", defaulCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Post default calendar error. Response ", "Post default calendar error. ");
  }
}

export async function getCustomRecruiterCalendar(fromDate: string, toDate: string, timeZone: string) {
  const params = { fromDate, toDate, timeZone };
  let response;
  try {
    response = await http().get("/api/availabilityDate", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Custom calendar error. Response ", "Custom calendar error. ");
  }
}

export async function postCustomCalendar(customCalendar: any) {
  let response;
  try {
    response = await http().post("/api/availabilityDate", customCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Post custom calendar error. Response ", "Post custom calendar error. ");
  }
}

export async function sendVideoIndexing(idVideo: string) {
  let response;
  try {
    response = await http().get("/api/videos/" + idVideo + "/sendVideoIndexing");
    return response.data;
  } catch (e) {
    showCatch(e, "Send video interview error. Response ", "Delete video. ");
  }
}

export async function getVideoInfo(id: string) {
  let response;
  try {
    response = await http().get("/api/videos/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Get video Info. Response ", "Get video Info. error ");
  }
}

export async function getVideo(id: string, rcStorage?: boolean) {
  let response;
  try {
    response = await http().get("/api/videos/" + id + "/stream", {
      params: { rcStorage },
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Get video stream. Response ", "Get video stream. error ");
  }
}

export async function getUserO365() {
  let response;
  try {
    response = await http().get("/api/users/o365");
    return response.data;
  } catch (e) {
    showCatch(e, "Get o365 Info. Response ", "Get o365 Info. error ");
  }
}

export async function getPhotoO365() {
  let response;
  try {
    response = await http().get("/api/users/o365/photo");
    return response.data;
  } catch (e) {
    showCatch(e, "Get o365 Photo. Response ", "Get o365 Photo. error ");
  }
}

export async function shareContentForClient(candidateId: string, processedContentForEmail: any) {
  let response;
  try {
    response = await http().post("/api/candidates/" + candidateId + "/candidateSharing", processedContentForEmail);
    return response.data;
  } catch (e) {
    showCatch(e, "Share candidate. Response ", "Share candidate. ");
  }
}

export async function fetchGlobalClients(filter: string) {
  const params = { filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/clients", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients list error. Response ", "Clients list error. ");
  }
}

export async function fetchAllGlobalClients() {
  let response;
  try {
    response = await http().get("/api/clients/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Clients list error. Response ", "Clients list error. ");
  }
}

export async function fetchGlobalClientList(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/clients", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients list error. Response ", "Clients list error. ");
  }
}

export async function getSharedCandidate(candidateId: string, id: string) {
  let response;
  try {
    response = await http().get("/api/candidates/" + candidateId + "/candidateSharing/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "GetSharedCandidate. Response ", "GetSharedCandidate. error ");
  }
}

function appendFileData(formData: any, file: File, checksum: string, subName = "") {
  let _formData = formData;
  if (file) {
    _formData.set(`fileName${subName}`, file?.name);
    _formData.append(`file${subName}`, file);
    _formData.set(`checksum${subName}`, checksum);
  }
  return _formData;
}

export async function uploadGlobalClient(name: string, file: File, checksum: string) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", name);
  bodyFormData = appendFileData(bodyFormData, file, checksum);

  let response;
  try {
    response = await http().post("/api/client", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload client. Response ", "Upload client. ");
  }
}

export async function updateGlobalClient(client: Client, file: File, checksum: string) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", client?.name);
  bodyFormData = appendFileData(bodyFormData, file, checksum);

  let response;
  try {
    response = await http().put("/api/client/" + client.id, bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload client. Response ", "Upload client. ");
  }
}

export async function putReviewClient(candidateId: string, sharedId: string, clientReview: any) {
  let response;
  try {
    response = await http().put("/api/candidates/" + candidateId + "/candidateSharing/" + sharedId, clientReview);
    return response.data;
  } catch (e) {
    showCatch(e, "Put review client. Response ", "Put review client. ");
  }
}

export async function deleteGlobalClient(clientId: string) {
  let response;
  try {
    response = await http().delete("/api/client/" + clientId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete client. Response ", "Delete client. ");
  }
}

export async function fetchAdminClientsList(filter?: string, onlyMissingGlobals?: boolean, branchId?: string, order?: string) {
  const params = { onlyMissingGlobals, filter, order } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  if (branchId) params.branchId = branchId;
  if (order) params.order = order;
  let response;
  try {
    response = await http().get("/api/clientAdmin", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients list error. Response ", "Clients list error. ");
  }
}

export async function getAllSharedCandidate(candidateId: string) {
  let response;
  try {
    response = await http().get("/api/candidates/" + candidateId + "/candidateSharing");
    return response.data;
  } catch (e) {
    showCatch(e, "Upload client. Response ", "Upload client. ");
  }
}

export async function uploadAdminClient(name: string, branches: string[], file: File, globalId?: string, showFindJobsUrl = true) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", name);
  bodyFormData.set("showFindJobsUrl", showFindJobsUrl ? "true" : "false");

  if (globalId) bodyFormData.set("globalId", globalId);
  branches?.forEach((item) => bodyFormData.append("branches[]", item));
  bodyFormData = appendFileData(bodyFormData, file, "a");

  let response;
  try {
    response = await http().post("/api/clientAdmin", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "GetSharedCandidate. Response ", "GetSharedCandidate. error ");
  }
}

export async function updateAdminClient(client: Client, branches: string[], file: File, showFindJobsUrl = true) {
  var bodyFormData = new FormData();
  bodyFormData.set("name", client?.name);
  bodyFormData.set("showFindJobsUrl", showFindJobsUrl ? "true" : "false");
  if (client?.globalId) bodyFormData.set("globalId", client?.globalId);
  branches?.forEach((item) => bodyFormData.append("branches[]", item));
  bodyFormData = appendFileData(bodyFormData, file, "a");

  let response;
  try {
    response = await http().put("/api/clientAdmin/" + client.id, bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload client. Response ", "Upload client. ");
  }
}

export async function deleteAdminClient(clientId: string) {
  let response;
  try {
    response = await http().delete("/api/clientAdmin/" + clientId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete client. Response ", "Delete client. ");
  }
}

/**
 * Function that upload the process data in the last position of list
 * @param {*} clients list to upload
 */
export async function uploadClientsList(clients: Client[]) {
  let response;
  try {
    response = await http().post("/api/clientBulk", { clients: clients });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload clients list error. Response ", "Upload clients list error. ");
  }
}

/**
 * Function that upload the process data in the last position of list
 * @param {*} clients list to upload
 */
export async function uploadLocalClientsList(clients: Client[]) {
  let response;
  try {
    response = await http().post("/api/clientAdminBulk", { clients: clients });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload clients list error. Response ", "Upload clients list error. ");
  }
}

export async function fetchPhasesProcessFunneling(
  processId: string,
  initDate: number,
  endDate: number,
  utmSource?: string,
  utmCampaign?: string,
  utmContent?: string,
  utmMedium?: string,
  utmTerm?: string,
  candidateIds?: string[]
) {
  const params = { initDate, endDate, utmSource, utmCampaign, utmContent, utmMedium, utmTerm, candidateIds: candidateIds };
  let response;
  try {
    response = await http().post("/api/funneling/process/" + processId, { ...params });
    return response.data;
  } catch (e) {
    showCatch(e, "Phases porcess funneling error. Response ", "Phases porcess funneling error. ");
  }
}

export async function fetchClientsStatsFunneling(clientId: string, initDate: number, endDate: number, filterFunneling?: string) {
  const params: any = { initDate, endDate };
  if (filterFunneling) params.filterFunneling = filterFunneling;

  let response;
  try {
    response = await http().get("/api/funneling/client/" + clientId, {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients funneling error. Response ", "Clients funneling error. ");
  }
}

export async function fetchPhaseStatusReportFunneling(processId: string, candidateIds?: string[]) {
  let response;
  try {
    response = await http().post("/api/funneling/phaseStatusReport/process/" + processId, { candidateIds });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients funneling error. Response ", "Clients funneling error. ");
  }
}

export async function fetchCandidateActivityReportFunneling(processId: string, initDate: number, endDate: number, candidateIds?: string[]) {
  const params = { initDate, endDate, candidateIds };
  let response;
  try {
    response = await http().post("/api/funneling/candidateActivity/" + processId, { ...params });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients activity funneling error. Response ", "Clients activity funneling error. ");
  }
}

export async function fetchRecruitesFunneling(initDate: number, endDate: number) {
  const params = { initDate, endDate };
  let response;
  try {
    response = await http().get("/api/funneling/topRecruiters", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients funneling error. Response ", "Clients funneling error. ");
  }
}

export async function fetchClientsFunneling(clientId: string, initDate: number, endDate: number) {
  const params = { initDate, endDate };

  let response;
  try {
    response = await http().get("/api/funneling/clientReport/client/" + clientId, {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Clients funneling error. Response ", "Clients funneling error. ");
  }
}

export async function fetchProcessFunneling(processId: string, filterFunneling?: string, candidateIds?: string[]) {
  let response;
  try {
    let url = `/api/funneling/client/process/${processId}`;
    if (filterFunneling) url = url + `?filterFunneling=${filterFunneling}`;
    response = await http().post(url, { candidateIds });
    return response.data;
  } catch (e) {
    showCatch(e, "Process funneling error. Response ", "Process funneling error. ");
  }
}

export async function resendWelcomeEmail(processId: string, candidates: []) {
  let response;
  try {
    response = await http().post("/api/mails/welcomeemail", {
      processId: processId,
      candidateIds: candidates,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Resend welcome mail error. Response ", "Resend welcome mail error. ");
  }
}

export async function sendEmails(processId: string, emailType: string, filter: any, emailContent?: any, sendProcessUrlToCandidate?: boolean) {
  let response;
  try {
    response = await http().post("/api/processes/" + processId + "/candidates/emails?emailType=" + emailType, {
      candidateIds: filter.candidateIds ? filter.candidateIds : undefined,
      filter: filter.filter ? filter.filter : undefined,
      filterTag: filter.filterTag ? filter.filterTag : undefined,
      filterPhases: filter.filterPhase
        ? filter.filterPhase?.map((fPhase: any) => {
            if (typeof fPhase === "string") return fPhase;
            else return JSON.stringify(fPhase);
          })
        : undefined,
      customMessage: emailContent,
      sendProcessUrlToCandidate,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Send email error. Response ", "Send email error. ");
  }
}

export async function uploadFunnelingReport(processId: string, emails: string[], file: File, fileName: string) {
  let response;
  try {
    response = await http().post("/api/funneling/process/" + processId + "/sendReport", {
      file: file,
      emails: emails,
      filename: fileName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Send report. Response ", "Send report. error ");
  }
}

export async function uploadClientsFunnelingReport(clientId: string, emails: string[], file: File, fileName: string) {
  let response;
  try {
    response = await http().post("/api/funneling/client/" + clientId + "/sendReport", {
      file: file,
      emails: emails,
      filename: fileName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Send client report. Response ", "Send client report. error ");
  }
}

export async function saveCandidateScoreNPS(candidateId: string, phaseIndex: number, candidateFeedBackScore: number, comment: string, processId: string, phaseType: number) {
  let response;
  try {
    response = await http().post("/api/feedback/candidate/" + candidateId, {
      phaseIndex: phaseIndex,
      score: candidateFeedBackScore,
      comment: comment,
      processId: processId,
      phaseType: phaseType,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Score report. Response ", "Candidate Score report. error ");
  }
}

export async function fetchIsNPScandidate(candidateId: string, phase: number) {
  let response;
  try {
    response = await http().get("/api/CandidateFeedback/candidates/" + candidateId + "/phaseIndex/" + phase);
    return response.data;
  } catch (e) {
    showCatch(e, "Candidates NPS. Response ", "Candidates NPS error. ");
  }
}

export async function fetchManageTenantRegions(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/regions", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Regions on tenant. Response ", "Regions on tenant error. ");
  }
}

export async function fetchManageTenantZones(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/regions/zones", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Regions on tenant. Response ", "Regions on tenant error. ");
  }
}

export async function fetchManageTenantBranches(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/regions/zones/branches", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Regions on tenant. Response ", "Regions on tenant error. ");
  }
}

export async function fetchUsersBranch(regionId: string, zoneId: string, branchId: string, roles?: string[]) {
  const params = { roles };
  if (roles?.length === 0) delete params.roles;

  let response;
  try {
    response = await http().get("/api/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId + "/users", { params });

    return response.data;
  } catch (e) {
    showCatch(e, "Users on tenant. Response ", "Users on tenant error. ");
  }
}

/**
 * Function that upload the process data in the last position of list
 * @param {*} regions list to upload
 */
export async function uploadBulkRegions(regions: any[]) {
  let response;
  try {
    response = await http().post("api/regions/createBulk", {
      regions: regions,
    });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload regions list error. Response ", "Upload regions list error. ");
  }
}

export async function uploadRegion(region: Region) {
  let response;
  try {
    response = await http().post("api/regions", {
      region,
    });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload regions list error. Response ", "Upload regions list error. ");
  }
}

export async function uploadZone(zone: Zone) {
  let response;
  try {
    response = await http().post("/api/regions/" + zone.regionId + "/zones", {
      zone,
    });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Zonees list error. Response ", "Upload Zonees list error. ");
  }
}

export async function uploadBranch(branch: Branch) {
  let response;
  try {
    response = await http().post("/api/regions/" + branch.regionId + "/zones/" + branch.zoneId + "/branches", { branch });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

export async function updateZone(zone: Zone) {
  let response;
  try {
    response = await http().put("/api/regions/" + zone.regionId + "/zones/" + zone.zoneId, { zone });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Zones list error. Response ", "Upload Zones list error. ");
  }
}

/**
 * Function that upload the branch data
 * @param {*} branchs list to upload
 */
export async function uploadBulkZones(zones: any) {
  let response;
  try {
    response = await http().post("/api/zones/createBulk", {
      zones: zones,
    });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Zones list error. Response ", "Upload Zones list error. ");
  }
}

export async function updateBranch(branch: Branch) {
  let response;
  try {
    response = await http().put("/api/regions/" + branch.regionId + "/zones/" + branch.zoneId + "/branches/" + branch.branchId, { branch });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

/**
 * Function that upload the branch data
 * @param {*} branchs list to upload
 */
export async function uploadBulkBranches(branches: any) {
  let response;
  try {
    response = await http().post("/api/branches/createBulk", {
      branches: branches,
    });
    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

export async function deleteRegion(regionId: string) {
  let response;
  try {
    response = await http().delete("/api/regions/" + regionId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete Region. Response ", "Delete Region. ");
  }
}

export async function updateRegion(editRegion: Region) {
  let response;
  try {
    response = await http().put("/api/regions/" + editRegion.regionId, {
      region: editRegion,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update Region. Response ", "Update Region. ");
  }
}

export async function deleteBranch(regionId: string, zoneId: string, branchId: string) {
  let response;
  try {
    response = await http().delete("/api/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete Branch. Response ", "Delete Branch. ");
  }
}

export async function deleteZone(regionId: string, zoneId: string) {
  let response;
  try {
    response = await http().delete("/api/regions/" + regionId + "/zones/" + zoneId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete Zone. Response ", "Delete Zone. ");
  }
}

/**
 * Function that upload the branch data
 * @param {*} linkUser list to upload
 */
export async function uploadLinkUser(regionId: string, zoneId: string, branchId: string, userId: any) {
  let response;

  try {
    response = await http().post("/api/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId + "/users/" + userId);

    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

export async function uploadBulkLinkUser(users: any) {
  let response;

  try {
    response = await http().post("/api/regions/zones/branches/users/createBulk", {
      users: users,
    });

    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

export async function deleteLinkUser(regionId: string, zoneId: string, branchId: string, userId: any) {
  let response;

  try {
    response = await http().delete("/api/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId + "/users/" + userId);

    return response.data;
  } catch (e) {
    showUploadCatch(e, "Upload Branches list error. Response ", "Upload Branches list error. ");
  }
}

export async function fetchProcesseseCandidateFeedbackTenant(initDate: number, endDate: number, isGlobal?: boolean, isDetails?: boolean) {
  const params = isGlobal !== undefined ? { initDate, endDate, isGlobal, isDetails } : { initDate, endDate, isDetails };

  let response;
  try {
    response = await http().get("api/feedback/fetch", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Candidates Feedback for tenant. Response ", "Candidates Feedback for tenant error. ");
  }
}

export async function fetchStageFeedbackTenant(initDate: number, endDate: number, isGlobal?: boolean) {
  const params = isGlobal !== undefined ? { initDate, endDate, isGlobal } : { initDate, endDate };

  let response;
  try {
    response = await http().get("api/feedback/phases", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Phases stage feedback for tenant. Response ", "Phases stage feedback for tenant error. ");
  }
}

export async function fetchAssesstmentTestListByType(phaseType: number, skillIdOrGroupId?: string, filter?: string, normsArray?: boolean) {
  let response;
  let params = {
    skillId: skillIdOrGroupId,
    groupId: skillIdOrGroupId,
    filter: filter,
    normsArray,
  };
  if (skillIdOrGroupId === "" || skillIdOrGroupId === undefined || skillIdOrGroupId === null) {
    delete params.skillId;
    delete params.groupId;
  }
  if (phaseType === PhaseType.hardSkill) {
    delete params.skillId;
  } else {
    delete params.groupId;
  }
  if (filter === "" || filter === undefined || filter === null) {
    delete params.filter;
  }
  try {
    response = await http().get(`/api/xpertTest/types/${phaseType}`, {
      params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Assesstment Test list error. Response ", "Assesstment Test list error. ");
  }
}

export async function fetchAssesstmentTestListByGroup(groupId: string, phaseType: number) {
  let response;
  try {
    response = await http().get("/api/xpertTest/groups/" + groupId + "/types/" + phaseType);
    return response.data;
  } catch (e) {
    showCatch(e, "Assesstment Test list error. Response ", "Assesstment Test list error. ");
  }
}

export async function fetchAssesstmentTopicList(testId: string, normId: string, phaseType: number) {
  let response;
  try {
    response = await http().get("/api/xpertTest/types/" + phaseType + "/tests/" + testId + "/norms/" + normId);
    return response.data;
  } catch (e) {
    showCatch(e, "Assesstment Test list error. Response ", "Assesstment Test list error. ");
  }
}

export async function openXpertSession(candidateId: string, phaseIndex: number) {
  let response;
  try {
    response = await http().get("/api/xpertTest/createTest/candidates/" + candidateId + "/phaseIndex/" + phaseIndex);
    return response.data;
  } catch (e) {
    showCatch(e, "XpertSession error. Response ", "XpertSession error. ");
  }
}

export async function fetchTestXpertSession(candidateId: string, testId: string, normId: string, phaseIndex: number) {
  let response;
  try {
    response = await http().get("/api/xpertTest/getTest/candidates/" + candidateId + "/testId/" + testId + "/normId/" + normId + "/phaseIndex/" + phaseIndex);
    return response.data;
  } catch (e) {
    showCatch(e, "Test received Xpert error. Response ", "Test received Xpert error. ");
  }
}
export async function updateXpertCatalog(isReset: boolean) {
  let response;
  try {
    response = await http().get("/api/xpertTest/catalog?reset=" + isReset);
    return response.data;
  } catch (e) {
    showCatch(e, "Xpert catalog update error. Response ", "Xpert catalog update error. ");
  }
}

export async function fetchLastXpertCatalogUpdates(filter?: string) {
  let response;
  try {
    response = await http().get("/api/xpertTest/catalog/updates", { params: { filter } });

    return response.data;
  } catch (e) {
    showCatch(e, "Xpert catalog update error. Response ", "Xpert catalog update error. ");
  }
}

export async function updateGlobalCompetencyFrameworkSkill(globalCompetencyFrameworkId: string, skillId: string) {
  let response;
  try {
    response = await http().put("/api/globalCompetenciesFrameworksSkills/" + globalCompetencyFrameworkId, {
      globalCompetencyFrameworkId,
      skillId,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Global competency framework skill updates error. Response ", "Global competency framework skill updates error. ");
  }
}

export async function uploadRecruitersFunnelingReport(emails: string[], file: File, fileName: string) {
  let response;
  try {
    response = await http().post("/api/funneling/sendReport", {
      file: file,
      emails: emails,
      filename: fileName,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Send recruiters report. Response ", "Send recruiters report. error ");
  }
}

export async function fetchAllTenantTags(page: number, size: number, order: any, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/tags", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch tags error. Response ", "fetch tags error. ");
  }
}

export async function fetchAllTagsGlobal(page: number, size: number, order: any, filter: string, onlyMissingGlobals: boolean = false) {
  const params = { page, size, order, filter, onlyMissingGlobals } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/tags/global", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch tags error. Response ", "fetch tags error. ");
  }
}
export async function fetchTagsGlobalFullList(order: string, filter: string, onlyMissingGlobals: boolean) {
  const params = { order, filter, onlyMissingGlobals } as any;
  if (!params.filter) delete params.filter;
  let response;
  try {
    response = await http().get("/api/tags/global", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch tags error. Response ", "fetch tags error. ");
  }
}

export async function fetchAllTagsDetailsProcess(processId: string) {
  let response;
  try {
    response = await http().get("/api/tags/" + processId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch tags error. Response ", "fetch tags error. ");
  }
}

export async function uploadGlobalTag(params: SendUpsertTag) {
  let response;

  try {
    response = await http().post("/api/tags/global", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Post tag error. Response ", "Post tag error. ");
  }
}

export async function updateGlobalTag(tagId: string, params: SendUpsertTag) {
  let response;

  try {
    response = await http().put("/api/tags/" + tagId + "/global", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tag error. Response ", "Put tag error. ");
  }
}

export async function deleteGlobalTag(tagId: string) {
  let response;
  try {
    response = await http().delete("/api/tags/" + tagId + "/global");
    return response.data;
  } catch (e) {
    showCatch(e, "Delete tag error. Response ", "Delete tag error. ");
  }
}

export async function uploadTenantTag(params: SendUpsertLocalTag) {
  let response;

  try {
    response = await http().post("/api/tagsAdmin", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Post tag error. Response ", "Post tag error. ");
  }
}

export async function updateTenantTag(tagId: string, params: SendUpsertLocalTag) {
  let response;

  try {
    response = await http().put("/api/tagsAdmin/" + tagId, params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tag error. Response ", "Put tag error. ");
  }
}

export async function deleteTenantTag(tagId: string) {
  let response;
  try {
    response = await http().delete("/api/tagsAdmin/" + tagId);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete tag error. Response ", "Delete tag error. ");
  }
}

export async function updateCandidateTag(tag: any, candidates: string[], processId: string) {
  let response;
  const params = { tag, candidates };
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/tag", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tag to candidates error. Response ", "Put tag to candidates error. ");
  }
}

export async function updateCandidateTags(tags: any[], candidates: string[], processId: string) {
  let response;
  const params = { tags, candidates };
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/tags", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tags to candidates error. Response ", "Put tags to candidates error. ");
  }
}

export async function resetAssessmentPhases(candidates: string[], processId: string) {
  let response;
  const params = { candidates };
  try {
    response = await http().put("/api/processes/" + processId + "/candidates/assessments/reset", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Put tag to candidates error. Response ", "Put tag to candidates error. ");
  }
}

export async function removeCandidateTag(tag: any, candidateId: string) {
  let response;
  try {
    response = await http().delete("/api/candidates/" + candidateId + "/tags/" + tag.id);
    return response.data;
  } catch (e) {
    showCatch(e, "Remove tag from candidates error. Response ", "Delete candidate tag error. ");
  }
}

export async function fetchPersonalProcesses() {
  let response;
  try {
    response = await http().get("/api/processes/personal");

    return response.data;
  } catch (e) {
    showCatch(e, "Personal Process List error. Response ", "Personal Process List error. ");
  }
}

export async function postPersonalProcess(process: any, candidate: any, liveInterview: any) {
  const params = { process, candidate, liveInterview };
  let response;
  try {
    response = await http().post("/api/processes/personal", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Post personal process error. Response ", "Post personal process error. ");
  }
}

export async function updatePersonalProcesses(id: string, process: any, candidate: any, liveInterview: any) {
  const params = { process, candidate, liveInterview };
  let response;
  try {
    response = await http().put("/api/processes/" + id + "/personal", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Process Close error. Response ", "Process Close error. ");
  }
}

export async function getDownloadReportSkillAssessmentTest(candidateId: string, testId: string, normId: string, indexPhase: number) {
  let response;
  try {
    response = await http().get("/api/candidateAssessment/downloadPDF/candidates/" + candidateId + "/tests/" + testId + "/norms/" + normId + "/phases/" + indexPhase);

    return response.data;
  } catch (e) {
    showCatch(e, "Get pdf skill assessment test error. Response ", "Get pdf skill assessment test error. ");
  }
}

export async function editProcess(processId: string, process: any, createTemplate: boolean, isGlobal: boolean, fromDraft: boolean = false, fromLogicSave: boolean = false) {
  const url = `/api/processes/${processId}${fromLogicSave ? "?fromLogicSave=true" : ""}`;
  let response;
  try {
    response = await http().put(
      url,
      { editProcess: process },
      {
        params: { createTemplate, isGlobal, fromDraft },
      }
    );
    return response.data;
  } catch (e) {
    showCatch(e, "Post process error. Response ", "Post process error. ", true);
    return response; //Return error
  }
}

export async function editDraftProcess(draftId: string, draft: any, fromLogicSave?: boolean) {
  const url = `/api/processes/drafts/${draftId}${fromLogicSave ? "?fromLogicSave=true" : ""}`;
  let response;
  try {
    response = await http().put(url, {
      editProcess: draft,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Post process error. Response ", "Post process error. ");
  }
}

export async function fetchTestsName(topicsId: string[]) {
  let response;
  try {
    let topicRequest = "";
    topicsId.forEach((topic: string, index: number) => {
      topicRequest += index === 0 ? `?topic=${topic}` : `&topic=${topic}`;
    });
    response = await http().get("/api/xpertTest/names" + topicRequest);
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch names xpert error. Response ", "Fetch names xpert error. ");
  }
}

export async function changeCandidatePhase(candidates: any) {
  const params = { candidates };
  let response;
  try {
    response = await http().post("/api/skipToNextPhase", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Post change phase error. Response ", "Post change phase process error. ");
  }
}

export async function updateVideoLiveInterviewCandidatesNotes(candidateId: string, phaseIndex: number, candidateNotes: any) {
  let response;
  try {
    response = await http().put("/api/candidates/" + candidateId + "/updateVideoLiveInterviewCandidatesNotes", {
      phaseIndex: phaseIndex,
      candidateNotes: candidateNotes,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate notes. Response ", "Update candidate notes. ");
  }
}

export async function updateVideoInterviewCandidatesNotes(candidateId: string, phaseIndex: number, candidateNotes: any) {
  let response;
  try {
    response = await http().put("/api/candidates/" + candidateId + "/updateVideoInterviewCandidatesNotes", {
      phaseIndex: phaseIndex,
      candidateNotes: candidateNotes,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate notes. Response ", "Update candidate notes. ");
  }
}

export async function updateCandidatePresentationRating(candidateId: string, phaseIndex: number, candidateNotes: any) {
  let response;
  try {
    response = await http().put("/api/candidates/" + candidateId + "/updateCandidatePresentationNotes", {
      phaseIndex: phaseIndex,
      candidateNotes: candidateNotes,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate notes. Response ", "Update candidate notes. ");
  }
}

export async function updateCandidatePhoneCallRating(candidateId: string, phaseIndex: number, candidateNotes: any) {
  let response;
  try {
    response = await http().put("/api/candidates/" + candidateId + "/updateCandidatePhoneCallNotes", {
      phaseIndex: phaseIndex,
      candidateNotes: candidateNotes,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate notes. Response ", "Update candidate notes. ");
  }
}

export async function getAcsCallData(candidateId: string) {
  let response;
  try {
    response = await http().post("/api/acs");
    return response.data;
  } catch (e) {
    showCatch(e, "Get Acs Call Data. Response ", "Get Acs Call Data. ");
  }
}

export async function updatePersonDetails(personId: string, details: any) {
  const params = { ...details };
  if (params.email === null || params.email === undefined || params.email === "") delete params.email;
  if (params.phone === null || params.phone === undefined || params.phone === "") delete params.phone;
  let response;
  try {
    response = await http().put("api/persons/" + personId + "/updatePersonDetails", {
      ...params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Update person details. Response ", "Update person details. ");
  }
}

export async function fetchManageTenantBranchesAll() {
  let response;
  try {
    response = await http().get("/api/regions/zones/branches/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get all branches. Response ", "fetchManageTenantBranchesAll");
  }
}

export async function getAllBranches() {
  const params = {
    page: 1,
    size: 100,
    order: ORDER_AD_USER_NAME_ASC,
  };
  let response;
  try {
    response = await http().get("/api/regions/zones/branches", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Get all regions branches. Response ", "Get all regions branches. ");
  }
}

export async function fetchGlobalCompetenciesFrameworkSkills(page: number, size: number, order: any, filter: any) {
  const params = { page, size, order, filter };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/globalCompetenciesFrameworksSkills", {
      params: params,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function emailConfirmation(processId: string, tempPersonalId: string) {
  let response;
  try {
    response = await http().post("/api/processes/" + processId + "/tempPersons/" + tempPersonalId + "/confirmEmail");
    return response.data;
  } catch (e) {
    showCatch(e, "Post email confirmation error. Response ", "Post email confirmation error. ");
  }
}

export async function importTalentSourceCandidates(processId: string, page: number, size: number) {
  const params = { page, size };
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/TScandidates", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Persons list error. Response ", "Persons list error. ");
  }
}

export async function fetchPersonsByEmail(filter: string) {
  const params = { filter, size: 100 } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/findPersonByEmail", { params: params });

    return response.data;
  } catch (e) {
    showCatch(e, "Persons list error. Response ", "Persons list error. ");
  }
}

export async function fetchVideoInterviewDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/videoInterview");

    return response.data;
  } catch (e) {
    showCatch(e, "Video Interview Data Retention error. Response ", "Video Interview Data Retention error. ");
  }
}

export async function fetchLiveInterviewDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/liveVideoInterview");

    return response.data;
  } catch (e) {
    showCatch(e, "Live Interview Data Retention error. Response ", "Live Interview Data Retention error. ");
  }
}

export async function fetchCandidateAssessmentDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/candidateAssesment");

    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Assessment Data Retention error. Response ", "Candidate Assessment Data Retention error. ");
  }
}

export async function fetchCandidateDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/person");

    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Data Retention error. Response ", "Candidate Data Retention error. ");
  }
}

export async function fetchTemporalPersonDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/tempPerson");

    return response.data;
  } catch (e) {
    showCatch(e, "Temporal Person Data Retention error. Response ", "Temporal Person Data Retention error. ");
  }
}

export async function fetchCandidatePresentationDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/candidatePresentation");

    return response.data;
  } catch (e) {
    showCatch(e, "Candidate Presentation Data Retention error. Response ", "Candidate Presentation Data Retention error. ");
  }
}

export async function runVideoInterviewDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/videoInterview/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Video Interview Data Retention error. Response ", "Run Video Interview Data Retention error. ");
  }
}

export async function runLiveInterviewDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/liveVideoInterview/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Live Interview Data Retention error. Response ", "Run Live Interview Data Retention error. ");
  }
}

export async function runCandidateAssessmentDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/candidateAssesment/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Candidate Assessment Data Retention error. Response ", "Run Candidate Assessment Data Retention error. ");
  }
}

export async function runCandidateDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/person/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Candidate Data Retention error. Response ", "Run Candidate Data Retention error. ");
  }
}

export async function runTemporalPersonDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/tempPerson/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Temporal Person Data Retention error. Response ", "Run Temporal Person Data Retention error. ");
  }
}

export async function runCandidatePresentationDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/candidatePresentation/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Candidate Presentation Data Retention error. Response ", "Run Candidate Presentation Data Retention error. ");
  }
}

export async function uploadCandidatePresentationVideo(candidateId: string, file: File, thumbnail: string, phaseIndex: any) {
  var bodyFormData = new FormData();
  bodyFormData.set("candidateId", candidateId);
  bodyFormData.set("phaseIndex", phaseIndex);
  bodyFormData.append("file", file);
  if (thumbnail !== undefined && thumbnail.length > 0) bodyFormData.set("thumbnail", thumbnail);

  let response;
  try {
    response = await http().post("/api/candidateVideoPresentation/create", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Upload candidate presentation video. Response ", "Upload candidate presentation video. ");
  }
}

export async function getCandidatePresentationVideo(candidateId: string, phaseIndex: number) {
  let response;
  try {
    response = await http().get("/api/candidateVideoPresentation/candidates/" + candidateId + "/phaseIndexes/" + phaseIndex + "/getLastCandidateVideoPresentationStream");
    return response.data;
  } catch (e) {
    showCatch(e, "Get candidate presentattion video stream. Response ", "Get candidate presentattion video stream. ");
  }
}

export async function getAllBrands() {
  let response;
  try {
    response = await http().get("/api/brands/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get brands. Response ", "Get brands. error ");
  }
}
export async function getAllCoBrands() {
  let response;
  try {
    response = await http().get("/api/cobrand/tenant");
    return response.data;
  } catch (e) {
    showCatch(e, "Get all cobrands of tenant. Response ", "Get all cobrands of tenant. error ");
  }
}

export async function getReplyToEmails(alias: string, params: any = {}) {
  let response;
  try {
    response = await http().get(`/api/tenants/${alias}/replyToEmails`, { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get replyToEmails. Response ", "Get replyToEmails. error ");
  }
}

export async function getTenantPublicInfo(alias: string) {
  let response;
  try {
    response = await http().get("/api/tenants/" + alias + "/publicInfo");
    return response.data;
  } catch (e) {
    showCatch(e, "Get tenant publicInfo. Response ", "Get tenant publicInfo. error ");
  }
}

export async function getCandidateActionValoration(candidateId: string, phaseIndex: number) {
  let response;
  try {
    response = await http().get("/api/candidateAction/candidates/" + candidateId + "/phasesIndexes/" + phaseIndex + "/getValoration");
    return response.data;
  } catch (e) {
    showCatch(e, "Get candidate action valoration. Response ", "Get candidate action valoration. error ");
  }
}

export async function fetchCalendarsList(page: number, _size: number, order: string, filter?: string, regionId?: string, zoneId?: string, branchId?: string, withAllBranches?: boolean) {
  const params = { page, size: _size ?? TABLE_SIZE, order, filter };
  if (filter === null || filter === undefined || filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/calendars/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId + `?withAllBranches=${withAllBranches ?? false}`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}
export async function fetchOutlookCalendars() {
  let response;
  try {
    response = await http().get("/api/calendars/getOutlookCalendars");
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}
export async function fetchProcessCalendars(regionId?: string, zoneId?: string, branchId?: string) {
  let response;
  try {
    response = await http().get("/api/calendars/regions/" + regionId + "/zones/" + zoneId + "/branches/" + branchId + "/process");
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function createCalendar(newCalendar: Calendar) {
  let response;
  try {
    response = await http().post("/api/calendars", newCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function updateCalendar(updatedCalendar: Calendar) {
  let response;
  try {
    response = await http().put("/api/calendars/" + updatedCalendar.id, updatedCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function deleteCalendar(calendarId: string) {
  let response;
  try {
    response = await http().delete("/api/calendars/" + calendarId);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function fetchNonAvailabilityCalendarsList(page: number, size: number, order: string, filter?: string, includeNADays = true) {
  const params = { page, size, order, filter, includeNADays };
  if (filter === null || filter === undefined || filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/nonAvailabilityCalendars", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function createNonAvailabilityCalendar(newNonAvailabilityCalendar: NonAvailabilityCalendar) {
  let response;
  try {
    response = await http().post("/api/nonAvailabilityCalendars", newNonAvailabilityCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function updateNonAvailabilityCalendar(updatedNonAvailabilityCalendar: NonAvailabilityCalendar) {
  let response;
  try {
    response = await http().put("/api/nonAvailabilityCalendars/" + updatedNonAvailabilityCalendar.id, updatedNonAvailabilityCalendar);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function deleteNonAvailabilityCalendar(holidayCalendarId: string) {
  let response;
  try {
    response = await http().delete("/api/nonAvailabilityCalendars/" + holidayCalendarId);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function linkCalendars(holidayCalendar: string, calendarsAssigned: string[]) {
  let response;
  try {
    response = await http().post("/api/nonAvailabilityCalendars/" + holidayCalendar + "/assignNonAvailabilityCalendar", {
      calendarsAssigned,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function deleteCalendarLinked(holidayCalendar: string, calendarId: string) {
  let response;
  try {
    response = await http().delete("/api/nonAvailabilityCalendars/" + holidayCalendar + "/removeAssignNonAvailabilityCalendar", {
      data: { calendarsAssigned: [calendarId] },
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function getNonAvailabilityCalendars(calendarId: string) {
  let response;

  try {
    response = await http().get("/api/calendars/" + calendarId + "/nonAvailabilityCalendars");
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function getAppointments(calendarId: string, fromDate: number, toDate: number) {
  let response;
  let params = { initDate: fromDate, endDate: toDate };
  try {
    response = await http().get("/api/calendars/" + calendarId + "/getAppointments", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function assignAppointment(assignAppointmentObj: CalendarAppointment) {
  let response;

  try {
    response = await http().post("/api/calendars/assignAppointment", assignAppointmentObj);
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}

export async function unsubscribePerson(personId: string) {
  let response;
  try {
    response = await http().put("/api/persons/" + personId + "/optout");
    return response.data;
  } catch (e) {
    showCatch(e, "Put optout error. Response ", "Put optout error. ");
  }
}

export async function getInfoProcessByExternalId(externalId: string, brandName: string, isoCountryCode: string) {
  let response;
  try {
    response = await http().get("/api/processes/external/" + externalId + "/countries/" + isoCountryCode + "/brands/" + brandName);
    return response.data;
  } catch (e) {
    showCatch(e, "Get process by externalId. Response ", "Get process by externalId. error ");
  }
}

export async function getTenantByBrandAndCountry(brandName: string, isoCountryCode: string) {
  let response;
  try {
    response = await http().get("/api/tenants/countries/" + isoCountryCode + "/brands/" + brandName);
    return response.data;
  } catch (e) {
    showCatch(e, "Get tenant by country and brand. Response ", "Get tenant by country and brand. error ");
  }
}

export async function getTenantPublicInfoByBrandAndCountry(brandName: string, isoCountryCode: string) {
  let response;
  try {
    response = await http().get("/api/tenants/countries/" + isoCountryCode + "/brands/" + brandName + "/publicInfo");
    return response.data;
  } catch (e) {
    showCatch(e, "Get tenant by country and brand. Response ", "Get tenant by country and brand. error ");
  }
}

export async function assignCandidateToProcess(transactionId: string, country: string, brand: string) {
  let response;
  try {
    response = await http().post("/api/processes/candidates/transaction", {
      transactionId,
      country,
      brand,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload candidate presentation video. Response ", "Upload candidate presentation video. ");
  }
}

export async function createVideoQuestionsBulk(videoQuestions: any[]) {
  let response;
  try {
    response = await http().post("/api/videoQuestionsLibrary/createBulk", {
      videoQuestions,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Upload video questions bulk. Response ", "Upload video questions bulk. ");
  }
}

export async function fetchJobs(order: any, page: number, size: number, filter: string) {
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/jobs", { params: params });

    return response.data;
  } catch (e) {
    showCatch(e, "Jobs List error. Response ", "Jobs List error. ");
  }
}

export async function fetchAllJobs() {
  let response;
  try {
    response = await http().get("/api/jobs/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Jobs List error. Response ", "Jobs List error. ");
  }
}

export async function deleteJob(item: any) {
  let response;
  try {
    response = await http().delete("/api/jobs/" + item.id);
    return response.data;
  } catch (e) {
    showCatch(e, "Jobs List error. Response ", "Jobs List error. ");
  }
}

export async function fetchJobDetails(id: string) {
  let response;
  try {
    response = await http().get("/api/jobs/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Jobs get error. Response ", "Jobs get error. ");
  }
}

export async function getJobProcesses() {
  let response;
  try {
    response = await http().get("/api/processes/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get Processes dropdown. Response ", "Get processes dropdown. error ");
  }
}

export async function createJob(data: any) {
  let response;
  try {
    if (data.id) {
      response = await http().put("/api/jobs/" + data.id, data);
    } else {
      response = await http().post("/api/jobs", data);
    }
    return response;
  } catch (e) {
    showCatch(e, "Create new Job. Response ", "Create new Job. error ");
  }
}

export async function fetchVideoIndexerSpeakers(videoId: string) {
  let response;
  try {
    response = await http().get("/api/videos/" + videoId + "/videoIndexerSpeaker");
    return response.data;
  } catch (e) {
    showCatch(e, "videoIndexerSpeakers get error. Response ", "videoIndexerSpeakers get error. ");
  }
}

export async function manageVideoIndexerSpeakers(request: string, data: any) {
  let response;
  try {
    if (request === "PUT") {
      response = await http().put("/api/videos/" + data.videoId + "/videoIndexerSpeaker", data);
    } else {
      response = await http().post("/api/videoIndexerSpeaker", data);
    }
    return response;
  } catch (e) {
    showCatch(e, "Create videoIndexerSpeakers. Response ", "Create videoIndexerSpeakers. error ");
  }
}

export async function deleteVideoIndexerSpeakers(videoId: string, speakersId: any) {
  let response;
  try {
    response = await http().delete("/api/videos/" + videoId + "/videoIndexerSpeaker", { data: { speakersId: speakersId } });
    return response.data;
  } catch (e) {
    showCatch(e, "Jobs List error. Response ", "Jobs List error. ");
  }
}

export async function fetchPersons(
  order: any,
  page: number,
  size: number,
  filter?: string,
  processName?: string,
  type?: any,
  runByCA?: any,
  branchId?: string,
  filterTag?: string[],
  filterTagsType?: string
) {
  const params = {
    page,
    size,
    order,
    filter,
    processName,
    type,
    runByCA,
    branchId,
    filterTag,
    filterTagsType,
  };
  if (params.filter === null || params.filter === undefined || params.filter === "") {
    delete params.filter;
  }
  if (params.processName === null || params.processName === undefined || params.processName === "") {
    delete params.processName;
  }
  if (params.type === null || params.type === undefined || params.type === "") {
    delete params.type;
  }
  if (params.runByCA === null || params.runByCA === undefined || params.runByCA === "") {
    delete params.runByCA;
  }
  if (params.branchId === null || params.branchId === undefined || params.branchId === "") {
    delete params.branchId;
  }
  if (params.filterTag === null || params.filterTag === undefined || params.filterTag.length === 0) {
    delete params.filterTag;
  }
  if (params.filterTagsType === null || params.filterTagsType === undefined || params.filterTagsType === "") {
    delete params.filterTagsType;
  }
  let response;
  try {
    response = await http().get("/api/persons", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Persons List error. Response ", "Persons List error. ");
  }
}

export async function fetchPersonsList(order: any, page: number, size: number, filter?: string) {
  const params: any = {
    page,
    size,
    order,
  };
  if (filter) params.filter = filter;
  let response;
  try {
    response = await http().get("/api/persons/list", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Persons List error. Response ", "Persons List error. ");
  }
}

export async function deletePerson(id: string) {
  let response;
  try {
    response = await http().delete(`/api/persons/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete Person error. Response ", "Delete Person error. ");
  }
}

export async function fetchPersonDetails(id: string, filter: string, type: any, runByCA: any) {
  const params = { filter, type, runByCA } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") {
    delete params.filter;
  }
  if (params.type === null || params.type === undefined || params.type === "") {
    delete params.type;
  }
  if (params.runByCA === null || params.runByCA === undefined || params.runByCA === "") {
    delete params.runByCA;
  }
  let response;
  try {
    response = await http().get("/api/persons/" + id, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Person get error. Response ", "Person get error. ");
  }
}

export async function fetchTenantVariables(filter: string) {
  const params = { filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") {
    delete params.filter;
  }
  let response;
  try {
    response = await http().get("/api/vars", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant variables get error. Response ", "Tenant variables get error. ");
  }
}

export async function createTenantVariable(data: any) {
  let response;
  try {
    response = await http().post("/api/vars", data);
    return response;
  } catch (e) {
    showCatch(e, "Create new tenant variables. Response ", "Create new tenant variables. error ");
  }
}

export async function updateTenantVariable(data: any, id: string) {
  let response;
  try {
    response = await http().put("/api/vars/" + id, data);
    return response;
  } catch (e) {
    showCatch(e, "Updated new tenant variables. Response ", "Updated new tenant variables. error ");
  }
}

export async function deleteTenantVariable(id: string) {
  let response;
  try {
    response = await http().delete("/api/vars/" + id);
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant variables error. Response ", "Tenant variables error. ");
  }
}

export async function fetchTenantTemplates() {
  let response;
  try {
    response = await http().get("/api/processTemplate");
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant Templates get error. Response ", "Tenant Templates get error. ");
  }
}

export async function fetchTemplateVars(templateId: string) {
  let response;
  try {
    response = await http().get("/api/templateVars/" + templateId);
    return response.data;
  } catch (e) {
    showCatch(e, "Templates vars get error. Response ", "Templates vars get error. ");
  }
}

export async function fetchJobVars(processId: string) {
  let response;
  try {
    response = await http().get("/api/jobVars/processes/" + processId);
    return response.data;
  } catch (e) {
    showCatch(e, "Job vars get error. Response ", "Job vars get error. ");
  }
}

export async function fetchJobVarsValues(jobId: string) {
  let response;
  try {
    response = await http().get("/api/jobVars/jobs/" + jobId);
    return response.data;
  } catch (e) {
    showCatch(e, "Job vars get error. Response ", "Job vars get error. ");
  }
}

export async function fetchCalendarsByBranches(branches: string[]) {
  let response;
  try {
    response = await http().get("/api/calendars/branches", {
      params: { branches: branches },
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendars by branches get error. Response ", "Calendars by branches get error. ");
  }
}

export async function fetchHomeProcessTemplates(page: number, size: number, filter: string, order: string, processTemplateFilter: ProcessTemplateFilters, userId?: string) {
  const params = { page, size, order, filter, processTemplateFilter, userId } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  let response;
  try {
    response = await http().get("/api/processTemplates/paginated", {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Home processTemplates get error. Response ", "Home processTemplates get error. ");
  }
}

export async function fetchJobsByBranch(order: any, page: number, size: number, filter: string, branchId: string, requisition: string) {
  const params = { page, size, order, filter, requisition } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") {
    delete params.filter;
  }

  let response;
  try {
    response = await http().get("/api/jobs/branches/" + branchId, {
      params: params,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Job By Branches List error. Response ", "Job By Branches List error. ");
  }
}

export async function fetchAllRequisitionJobs() {
  let response;
  try {
    response = await http().get("/api/jobs/requisitions");
    return response.data;
  } catch (e) {
    showCatch(e, "Job By Branches List error. Response ", "Job By Branches List error. ");
  }
}

export async function fetchOperationalProcessesReport(initDate: string, endDate: string, regionId?: string, branchId?: string, professionId?: string) {
  const params = { initDate, endDate, regionId, branchId, professionId };

  let response;
  try {
    response = await http().get("/api/process/getReport", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Process operational report error. Response ", "Process operational report error. ");
  }
}

export async function fetchOperationalRecruitersReport(initDate: string, endDate: string, regionId?: string, branchId?: string, role?: string) {
  const params = { initDate, endDate, regionId, branchId, role };

  let response;
  try {
    response = await http().get("/api/recruiterReports", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Process operational report error. Response ", "Process operational report error. ");
  }
}

export async function fetchOperationalRecruitersReportGlobal(initDate: number, endDate: number, regionId?: string, branchId?: string, role?: string) {
  const params = { initDate, endDate, regionId, branchId, role };

  let response;
  try {
    response = await http().get("/api/recruiterReportsGlobal", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Process operational report global error. Response ", "Process operational report global error. ");
  }
}

export async function fetchJobPostingReport(initDate: string, endDate: string, isGlobal: boolean) {
  const params = { initDate, endDate };
  let response;
  try {
    response = await http().get(`/api/jobDescriptionSchedule/report?global=${isGlobal}`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Job Posting report error. Response ", "Job Posting report error. ");
  }
}

export async function fetchProcessNps(processId: string, candidateIds?: string[]) {
  let response;
  try {
    response = await http().post("/api/feedback/processes/" + processId, { candidateIds });
    return response.data;
  } catch (e) {
    showCatch(e, "Process nps error. Response ", "Process nps error. ");
  }
}

export async function getPersonByExternalId(externalId: string) {
  let response;
  try {
    response = await http().get("/api/persons/external/" + externalId);
    return response.data;
  } catch (e) {
    showCatch(e, "Get person by externalId. Response ", "Get person by externalId. error ");
  }
}

export async function updateCandidateCV(processId: string, candidateId: string, cv: any) {
  let response;
  try {
    response = await http().post("/api/processes/" + processId + "/candidates/" + candidateId + "/uploadCV", { cv: cv });
    return response.data;
  } catch (e) {
    showCatch(e, "Update candidate cv. Response ", "Update candidate cv. ");
  }
}

export async function fetchTalentSourceCandidates(professionId: string) {
  let response;
  try {
    response = await http().get("/api/professions/" + professionId + "/TSCandidates");
    return response.data;
  } catch (e) {
    showCatch(e, "Get talent source candidates. Response ", "Get talent source candidates. error ");
  }
}

export async function fetchAllServiceTemplates() {
  let response;
  try {
    response = await http().get("/api/serviceTemplates/all");

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function fetchServiceTemplatesPaginated(filters: FiltersResults) {
  const { page, perPage: size, sortBy, sortDirection, search, type, templateType } = filters;
  const params = { page, size, sortBy, sortDirection, search, type, templateType };
  if (params.sortBy === undefined || params.sortBy === null || params.sortDirection === null) {
    delete params.sortBy;
  }
  if (params.sortDirection === undefined || params.sortDirection === "" || params.sortDirection === null) {
    delete params.sortDirection;
  }
  if (params.templateType === "" || params.templateType === undefined || params.templateType === null || params.templateType === "AllTemplates") {
    delete params.templateType;
  }
  if (params.type === "" || params.type === undefined || params.type === null) {
    delete params.type;
  }
  if (params.search === "" || params.search === undefined || params.search === null) {
    delete params.search;
  }
  let response;
  try {
    response = await http().get("/api/serviceTemplates", { params: params });
    return response;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function fetchServiceTemplatesFilters(type?: number, professionId?: string) {
  let params: any = { professionId, type };
  Object.keys(params).forEach((key: string) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  let response;
  try {
    response = await http().get("/api/serviceTemplates/filters", { params });

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function fetchServiceTemplateByIdAndType(serviceTemplateId: string, type: number) {
  let response;
  try {
    response = await http().get(`/api/serviceTemplates/${serviceTemplateId}/type/${type}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function uploadServiceTemplate(serviceTemplate: ServiceCreationI) {
  let response;
  try {
    response = await http().post(`/api/serviceTemplates`, serviceTemplate);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function uploadService(service: ServiceCreationI) {
  let response;
  try {
    response = await http().post(`/api/services`, service);
    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function deleteServiceTemplate(serviceTemplateId: string) {
  let response;
  try {
    response = await http().delete(`/api/serviceTemplates/${serviceTemplateId}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function deleteServiceTemplateContent(serviceTemplateContentId: string) {
  let response;
  try {
    response = await http().delete(`/api/serviceTemplateContents/${serviceTemplateContentId}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function deleteServiceContent(serviceContentId: string) {
  let response;
  try {
    response = await http().delete(`/api/serviceContents/${serviceContentId}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete serviceContent error. Response ", "Delete serviceContent error. ");
  }
}

export async function fetchServicesPaginated(filters: FiltersResults) {
  const { page, perPage: size, sortBy, sortDirection, search, type, serviceType } = filters;
  const params = { page, size, sortBy, sortDirection, search, type, serviceType };
  if (params.sortBy === undefined || params.sortBy === null || params.sortDirection === null) {
    delete params.sortBy;
  }
  if (params.sortDirection === undefined || params.sortDirection === "" || params.sortDirection === null) {
    delete params.sortDirection;
  }
  if (params.serviceType === "" || params.serviceType === undefined || params.serviceType === null || params.serviceType === "AllServices") {
    delete params.serviceType;
  }
  if (params.type === "" || params.type === undefined || params.type === null) {
    delete params.type;
  }
  if (params.search === "" || params.search === undefined || params.search === null) {
    delete params.search;
  }

  let response;
  try {
    response = await http().get("/api/services", { params: params });
    return response;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function fetchOneService(serviceId: string) {
  let response;
  try {
    response = await http().get("/api/services/" + serviceId);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function fetchOneServiceDetail(serviceId: string, page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  try {
    response = await http().get("/api/services/" + serviceId + "/assessments", {
      params,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function fetchOneServiceAssessmentDetail(serviceId: string, personId: string) {
  let response;
  try {
    response = await http().get("/api/services/" + serviceId + "/assessments/" + personId);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function updateServiceAssessmentPublic(
  serviceId: string,
  personId: string,
  updateData: {
    status: { from: number; to: number };
    scheduleDate?: number;
    timezone?: string;
    assignRecruiterId?: string;
    assignCalendarId?: string;
  }
) {
  let response;
  try {
    response = await http().put("/api/services/" + serviceId + "/assessments/" + personId + "/public", updateData);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function reviewServiceAssessment(serviceId: string, personId: string, reviewData: any) {
  let response;
  try {
    response = await http().put("/api/services/" + serviceId + "/assessments/" + personId + "/review", reviewData);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function inviteServiceAssessmentInterview(serviceId: string, personId: string, inviteData: any) {
  let response;
  try {
    response = await http().put("/api/services/" + serviceId + "/assessments/" + personId + "/invite", inviteData);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}
export async function rescheduleServiceAssessmentInterview(serviceId: string, personId: string, inviteData: any) {
  let response;
  try {
    response = await http().put("/api/services/" + serviceId + "/assessments/" + personId + "/reschedule", inviteData);

    return response.data;
  } catch (e) {
    showCatch(e, "Process List error. Response ", "Process List error. ");
  }
}

export async function postJobDescriptionProcess(jobDescriptionProcess: any) {
  let response;
  try {
    response = await http().post("/api/jobDescriptionProcesses", jobDescriptionProcess);
    return response.data;
  } catch (e) {
    showCatch(e, "Post jobDescriptionProcesses error. Response ", "Post process error. ");
  }
}

export async function postJobDescriptionProcessDraft(jobDescriptionProcess: any, logicSave?: boolean) {
  const url = `/api/jobDescriptionProcesses/draft${logicSave ? "?logicSave=true" : ""}`;
  let response;
  try {
    response = await http().post(url, jobDescriptionProcess);
    return response.data;
  } catch (e) {
    showCatch(e, "Post jobDescriptionProcesses draft error. Response ", "Post process error. ");
  }
}

export async function postJobDescriptionProcessTemplate(jobDescriptionProcess: any) {
  let response;
  try {
    response = await http().post("/api/jobDescriptionProcesses/template", jobDescriptionProcess);
    return response.data;
  } catch (e) {
    showCatch(e, "Post jobDescriptionProcesses template error. Response ", "Post process error. ");
  }
}

export async function fetchScreeningQuestionAttribute(page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  try {
    response = await http().get("/api/screeningQuestionAttribute", { params });

    return response.data;
  } catch (e) {
    showCatch(e, "Get Screening Question Attribute error. Response ", "Get Screening Question Attribute error. ");
  }
}

export async function fetchAllScreeningQuestionAttribute() {
  let response;
  try {
    response = await http().get("/api/screeningQuestionAttribute/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get Screening Question Attribute error. Response ", "Get Screening Question Attribute error. ");
  }
}

export async function postScreeningQuestionAttribute(screeningQuestionAttribute: any) {
  let response;
  try {
    response = await http().post("/api/screeningQuestionAttribute", screeningQuestionAttribute);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Screening Question Attribute error. Response ", "Post Screening Question Attribute error. ");
  }
}

export async function putScreeningQuestionAttribute(id: string, screeningQuestionAttribute: any) {
  let response;
  try {
    response = await http().put(`/api/screeningQuestionAttribute/${id}`, screeningQuestionAttribute);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Screening Question Attribute error. Response ", "Update Screening Question Attribute error. ");
  }
}

export async function deleteScreeningQuestionAttribute(id: string) {
  let response;
  try {
    response = await http().delete(`/api/screeningQuestionAttribute/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete Screening Question Attribute error. Response ", "Delete Screening Question Attribute error. ");
  }
}

export async function fetchTenantScreeningQuestionAttribute(page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;

  try {
    response = await http().get("/api/tenantScreeningQuestionAttribute", {
      params,
    });

    return response.data;
  } catch (e) {
    showCatch(e, "Get Screening Question Attribute error. Response ", "Get Screening Question Attribute error. ");
  }
}

export async function fetchAllTenantScreeningQuestionAttribute() {
  let response;
  try {
    response = await http().get("/api/tenantScreeningQuestionAttribute/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get Screening Question Attribute error. Response ", "Get Screening Question Attribute error. ");
  }
}

export async function postTenantScreeningQuestionAttribute(screeningQuestionAttribute: any) {
  let response;
  try {
    response = await http().post("/api/tenantScreeningQuestionAttribute", screeningQuestionAttribute);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Screening Question Attribute error. Response ", "Post Screening Question Attribute error. ");
  }
}

export async function putTenantScreeningQuestionAttribute(id: string, screeningQuestionAttribute: any) {
  let response;
  try {
    response = await http().put(`/api/tenantScreeningQuestionAttribute/${id}`, screeningQuestionAttribute);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Screening Question Attribute error. Response ", "Update Screening Question Attribute error. ");
  }
}

export async function deleteTenantScreeningQuestionAttribute(id: string) {
  let response;
  try {
    response = await http().delete(`/api/tenantScreeningQuestionAttribute/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete Screening Question Attribute error. Response ", "Delete Screening Question Attribute error. ");
  }
}

export async function fetchOneTenantScreeningQuestionAttribute(id: string) {
  let response;

  try {
    response = await http().get(`/api/tenantScreeningQuestionAttribute/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Get One Tenant Screening Question Attribute error. Response ", "Get One Tenant Screening Question Attribute error. ");
  }
}

export async function getQuestionsReferenceCheck(candidateId: string, referenceId: string) {
  let response;
  response = await http().get(`/api/candidate/${candidateId}/reference/${referenceId}`);
  return response.data;
}

export async function putAnswersReferenceCheck(candidateId: string, referenceId: string, payload: any, securityHeaders: any) {
  let response;
  try {
    response = await http(false, securityHeaders).put(`/api/candidate/${candidateId}/reference/${referenceId}`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Update Answers of Reference Candidate error. Response ", "Update Answers of Reference Candidate error. ");
  }
}

export async function getQuestionsReference(candidateId: string, phaseIndex: number) {
  let response;
  const params = { phaseIndex };
  response = await http().get(`/api/candidate/${candidateId}/reference`, {
    params,
  });
  return response.data;
}

export async function getCoBrands() {
  let response;
  try {
    response = await http().get("/api/cobrand/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get all cobrands. Response ", "Get all cobrands. error ");
  }
}

export async function fetchExternalSystem(filter?: string) {
  let response;
  const params = { filter };
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  try {
    response = await http().get("/api/externalSystems", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get all External Systems Response ", "Get all External Systems. error ");
  }
}

export async function updateExternalSystem(payload: any) {
  let response;
  try {
    response = await http().post(`/api/externalSystems`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Create or Update External Systems error. Response ", "Create or Update External Systems error. ");
  }
}

export async function deleteExternalSystem(id: string) {
  let response;
  try {
    response = await http().delete(`/api/externalSystems/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete External Systems error. Response ", "Delete External Systems error. ");
  }
}

export async function fetchExternalSystemOperation() {
  let response;
  try {
    response = await http().get("/api/externalSystemsOperations");
    return response.data;
  } catch (e) {
    showCatch(e, "Get all External Systems Operations Response ", "Get all External Systems Operations. error ");
  }
}

export async function updateExternalSystemOperation(payload: any) {
  let response;
  try {
    response = await http().post(`/api/externalSystemsOperations`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Create or Update External Systems Operations error. Response ", "Create or Update External Systems Operations error. ");
  }
}

export async function deleteExternalSystemOperation(id: string) {
  let response;
  try {
    response = await http().delete(`/api/externalSystemsOperations/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete External Systems Operations error. Response ", "Delete External Systems Operations error. ");
  }
}

export async function fetchExternalSystemOperationTenant(filters: Filters) {
  let response;
  let params: any = { ...filters };
  if (params.search === "") delete params.search;
  try {
    response = await http().get("/api/externalSystemsOperationsTenant", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get all External Systems Operations Tenant Response ", "Get all External Systems Operations Tenant . error ");
  }
}

export async function updateExternalSystemOperationTenant(payload: any) {
  let response;
  try {
    response = await http().post(`/api/externalSystemsOperationsTenant`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Create or Update External Systems Operations Tenant  error. Response ", "Create or Update External Systems Operations Tenant error. ");
  }
}

export async function deleteExternalSystemOperationTenant(form: any) {
  let response;
  try {
    response = await http().delete(`/api/externalSystemsOperationsTenant/${form.systemId}/${form.operationId}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Delete External Systems Operations Tenant  error. Response ", "Delete External Systems Operations Tenant  error. ");
  }
}

export async function fetchBrand(page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  try {
    response = await http().get("/api/brands", { params });
    return response;
  } catch (e) {
    showCatch(e, "Get Brand error. Response ", "Get Brand error. ");
  }
}

export async function postBrand(payload: any, imageFile: any) {
  let bodyFormData = new FormData();
  bodyFormData.set("id", payload?.id);
  bodyFormData.set("name", payload?.name);
  bodyFormData.set("brandColor", payload?.brandColor);
  if (payload?.officialId) bodyFormData.set("officialId", payload?.officialId);
  if (payload?.officialName) bodyFormData.set("officialName", payload?.officialName);
  if (payload?.code) bodyFormData.set("code", payload?.code);
  if (payload?.externalCode) bodyFormData.set("externalCode", payload?.externalCode);
  if (payload?.brandImage) bodyFormData.set("brandImage", payload?.brandImage);
  bodyFormData = appendFileData(bodyFormData, imageFile, "asd", "emailHeaderImage");
  bodyFormData.set("type", imageFile.type);
  if (payload?.cammioBrandingId?.LOCAL) bodyFormData.set("cammioBrandingId.LOCAL", JSON.stringify(payload?.cammioBrandingId?.LOCAL));
  if (payload?.cammioBrandingId.DEV) bodyFormData.set("cammioBrandingId.DEV", JSON.stringify(payload?.cammioBrandingId.DEV));
  if (payload?.cammioBrandingId.TEST) bodyFormData.set("cammioBrandingId.TEST", JSON.stringify(payload?.cammioBrandingId.TEST));
  if (payload?.cammioBrandingId.UAT) bodyFormData.set("cammioBrandingId.UAT", JSON.stringify(payload?.cammioBrandingId.UAT));
  if (payload?.cammioBrandingId.PROD) bodyFormData.set("cammioBrandingId.PROD", JSON.stringify(payload?.cammioBrandingId.PROD));
  let response;
  try {
    response = await http().post("/api/brands", bodyFormData);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Brand error. Response ", "Post Brand error. ");
  }
}

export async function putBrand(id: number, payload: any, imageFile: any) {
  let bodyFormData = new FormData();
  bodyFormData.set("id", payload?.id);
  bodyFormData.set("name", payload?.name);
  bodyFormData.set("brandColor", payload?.brandColor);
  if (payload?.officialId) bodyFormData.set("officialId", payload?.officialId);
  if (payload?.officialName) bodyFormData.set("officialName", payload?.officialName);
  if (payload?.code) bodyFormData.set("code", payload?.code);
  if (payload?.externalCode) bodyFormData.set("externalCode", payload?.externalCode);
  if (payload?.brandImage) bodyFormData.set("brandImage", payload?.brandImage);
  bodyFormData = appendFileData(bodyFormData, imageFile, "asd", "emailHeaderImage");
  bodyFormData.set("type", imageFile.type);
  if (payload?.cammioBrandingId?.LOCAL) bodyFormData.set("cammioBrandingId.LOCAL", JSON.stringify(payload?.cammioBrandingId?.LOCAL));
  if (payload?.cammioBrandingId.DEV) bodyFormData.set("cammioBrandingId.DEV", JSON.stringify(payload?.cammioBrandingId.DEV));
  if (payload?.cammioBrandingId.TEST) bodyFormData.set("cammioBrandingId.TEST", JSON.stringify(payload?.cammioBrandingId.TEST));
  if (payload?.cammioBrandingId.UAT) bodyFormData.set("cammioBrandingId.UAT", JSON.stringify(payload?.cammioBrandingId.UAT));
  if (payload?.cammioBrandingId.PROD) bodyFormData.set("cammioBrandingId.PROD", JSON.stringify(payload?.cammioBrandingId.PROD));

  let response;
  try {
    response = await http().put(`/api/brands/${id}`, bodyFormData);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Brand error. Response ", "Update Brand error. ");
  }
}

export async function deleteBrand(id: string) {
  let response;
  try {
    response = await http().delete(`/api/brands/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete Brand error. Response ", "Delete Brand error. ");
  }
}

export async function fetchRecruiterAttention(regionId?: string, zoneId?: string, branchId?: string) {
  let response;
  const headers = { regionId: regionId ? regionId : "", zoneId: zoneId ? zoneId : "", branchId: branchId ? branchId : "" };

  try {
    response = await http(false, headers).get("/api/recruiter/attention");
    return response.data;
  } catch (e) {
    showCatch(e, "Get recruiter attention error. Response ", "Get recruiter attention error. ");
  }
}

export async function fetchRecruiterActivity(regionId?: string, zoneId?: string, branchId?: string) {
  let response;
  const headers = { regionId: regionId ? regionId : "", zoneId: zoneId ? zoneId : "", branchId: branchId ? branchId : "" };
  try {
    response = await http(false, headers).get("/api/recruiter/activity");
    return response.data;
  } catch (e) {
    showCatch(e, "Get recruiter activity error. Response ", "Get recruiter activity error. ");
  }
}

export async function postRecruiterActivity(payload: any) {
  let response;
  try {
    response = await http().post("/api/recruiter/activity", payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Recruiter Activity error. Response ", "Post Recruiter Activity error. ");
  }
}

export async function downloadAllCVs(processId: string, selectedCandidates: string[], limit: number) {
  let response;
  try {
    response = await http().post(`/api/processes/${processId}/candidates/downloadAllCVs`, {
      selectedCandidates: selectedCandidates,
      limit: limit,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Post Brand error. Response ", "Post Brand error. ");
  }
}

export async function downloadAllAdditionalFiles(processId: string, selectedCandidates: string[], limit: number, docType?: string) {
  let response;
  try {
    response = await http().post(`/api/processes/${processId}/candidates/downloadAllAdditionalDocs/${docType}`, {
      selectedCandidates: selectedCandidates,
      limit: limit,
    });
    return response.data;
  } catch (e) {
    showCatch(e, "Post Brand error. Response ", "Post Brand error. ");
  }
}

export async function fetchLinks(page: number, size: number, order: string, filter: string) {
  let response;
  const params = { page, size, order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  try {
    response = await http().get("/api/links", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get Brand error. Response ", "Get Brand error. ");
  }
}

export async function fetchAllLinks() {
  let response;
  try {
    response = await http().get("/api/links/all");
    return response.data;
  } catch (e) {
    showCatch(e, "Get recruiter activity error. Response ", "Get recruiter activity error. ");
  }
}

export async function postLink(payload: any) {
  let response;
  try {
    response = await http().post("/api/links", payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Recruiter Activity error. Response ", "Post Recruiter Activity error. ");
  }
}

export async function updateReplyToEmails(payload: any) {
  let response;
  try {
    response = await http().post("/api/tenants/updateReplyToEmails", payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Post ReplyToEmail error. Response ", "Post ReplyToEmail error. ");
  }
}

export async function putLink(linkId: string, payload: any) {
  let response;
  try {
    response = await http().put("/api/links/" + linkId, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Recruiter Activity error. Response ", "Post Recruiter Activity error. ");
  }
}

export async function deleteLink(linkId: string) {
  let response;
  try {
    response = await http().delete("/api/links/" + linkId);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Recruiter Activity error. Response ", "Post Recruiter Activity error. ");
  }
}

export async function fetchRecruiterCandidateActivity(candidateId: string) {
  let response;
  try {
    response = await http().get(`/api/recruiter/candidate/${candidateId}/activity`);
    return response.data;
  } catch (e) {
    showCatch(e, "Get recruiter candidate activity error. Response ", "Get recruiter candidate activity error. ");
  }
}

/**
 * Function that selects and builds the available days to assign an appointment based on the month selected in the calendar
 * @param {string} processId uuid of a process
 * @param {string} candidateId uuid of a candidate
 * @param {number} start start timestamp slots
 * @param {number} end end timestamp slots
 * @param {string} phase number of phase
 */

export async function getSlotsByMonth(processId: string, candidateId: string, start: number, end: number, phase: number, extraLive?: string) {
  let response;
  try {
    response = await http().get(`/api/processes/${processId}/candidates/${candidateId}/calendar?start=${start}&end=${end}${extraLive ? "&live=" + extraLive : "&phase=" + phase}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Get recruiter candidate activity error. Response ", "Get recruiter candidate activity error. ");
  }
}
/**
 *
 * Function that returns the information of the referenced associated to its candidate.
 *
 * @param {string} processId process identifier
 *
 */

export async function downloadReferencesByCandidates(processId: string) {
  let response;
  try {
    response = await http().get("/api/processes/" + processId + "/references");
    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}
export async function postService(payload: ServiceCreationI) {
  let response;
  try {
    response = await http().post("/api/services", payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Service error. Response ", "Post Service error. ");
  }
}

export async function downloadServiceAssessments(serviceId: string) {
  let response;
  try {
    response = await http().get(`/api/services/${serviceId}/assessments/download`);
    return response.data;
  } catch (e) {
    showCatch(e, "Download service assessment error. Response ", "Download service assessment error. ");
  }
}

export async function updateExpirationDateOnPhase(processId: string, candidateId: string, payload: any) {
  let response;
  try {
    response = await http().put(`/api/processes/${processId}/candidates/${candidateId}/extendExpiration`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Update expirationDAte on phase error. Response ", "Update expirationDAte on phase error. ");
  }
}

export async function getToken(payload: any) {
  let response;
  try {
    response = await http().post(`/api/auth/token`, payload);
    return response.data;
  } catch (e) {
    showCatch(e, "Error getting tokens by code. Response ", "Error getting tokens by code. ");
  }
}

export async function getRefreshToken() {
  let response;
  try {
    response = await http().post(`/api/auth/refresh`);
    return response.data;
  } catch (e) {
    showCatch(e, "Error getting tokens by refreshToken. Response ", "Error getting tokens by refreshToken. ");
  }
}

export async function getBingMapLocation(queryString: string, key?: string) {
  let response;

  //this is to force the returned values from bing, as otherwise fail the integration
  //this is not related to the selected language, it's forced, ie: Vonq integration needs values in italian only
  //for instance return Firenze instead of Florence or Milano instead of Milan.
  const selectedTenantId = store?.getState()?.tenant?.selectedTenant?.id;
  let culture = "en";
  switch (selectedTenantId) {
    case 115: //115 = adecco italy
      culture = "it";
      break;
    default:
      break;
  }

  const params = { queryString, key, culture: culture } as any;
  if (params.queryString === null || params.queryString === undefined || params.queryString === "") delete params.queryString;
  try {
    response = await http().get("/api/bingMap/location", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get BingMap Location error. Response ", "Get BingMap Location error. ");
  }
}

export async function getSmartRecruitmentRecomendations(categoryId: string, processName: string, zoneId?: string) {
  let response;
  const params = { categoryId, processName, zoneId };
  try {
    response = await http().get("/api/smartRecruitment/recomendations", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get Smart Recruitment Recomendation error. Response ", "Get Smart Recruitment Recomendation error. ");
  }
}

export async function getSmartQualificationRecomendations(categoryId: string, type: number) {
  let response;
  const params = { categoryId, type };
  try {
    response = await http().get("/api/smartRecruitment/qualifications", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get Smart Qualification Recomendation error. Response ", "Get Smart Qualification Recomendation error. ");
  }
}

// Not tested
// Beacon Request (analytics) to avoid abort execution when abort request
export function sendBeaconRequest(processId: string, hardDelete = false) {
  const url = `${API_BASE_PATH}api/processes/${processId}?hardDelete=${hardDelete}`;
  const headers: any = getBasicHeaders();
  fetch(url, { method: "DELETE", keepalive: true, headers });
}

//? Integration Error Logs

export async function getAllIntegrationErrors(filters: FiltersResults) {
  const { integrationType, actionType, page, perPage, search, sortBy, sortDirection, date } = filters;

  let initDate;
  let endDate;

  if (date) {
    initDate = new Date(date.split(",")[0]).valueOf();
    endDate = new Date(date.split(",")[1]).valueOf();
  }
  const params = {
    integrationType,
    actionType,
    page,
    perPage,
    search,
    sortBy,
    sortDirection,
    initDate,
    endDate,
  };

  if (!integrationType) delete params.integrationType;
  if (!search) delete params.search;
  if (!actionType) delete params.actionType;
  if (!initDate) delete params.initDate;
  if (!endDate) delete params.endDate;

  try {
    return await http().get("/api/activity/integrationErrors", { params: params });
  } catch (e) {
    showCatch(e, "Get All Integration errors. Response ", "Get All Integration errors. ");
  }
}

export async function getProcessTenant(processId: string) {
  let response;
  try {
    response = await http().get(`/api/processes/${processId}/checkTenant`);
    return response.data;
  } catch (e) {
    showCatch(e, "Get Process Tenant error. Response ", "Get Process Tenant error. ");
  }
}
export async function getJobDescriptionTenant(jdId: string) {
  let response;
  try {
    response = await http().get(`/api/jobDescription/${jdId}/checkTenant`);
    return response.data;
  } catch (e) {
    showCatch(e, "Get Job Description Tenant error. Response ", "Get Job Description Tenant error. ");
  }
}

export async function fetchRequisitionIdInTenant(requisitionId: string) {
  let response;
  let params = { requisitionId };
  try {
    response = await http().get(`/api/jobs/requisition`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get Requisition Tenant error. Response ", "Get Requisition Tenant error. ");
  }
}

export async function sendForeachData(data: any, type: string) {
  let response;
  try {
    response = await http().post(`/api/proactive/populate?type=${type}`, { data: data });
    return response.data;
  } catch (e) {
    showCatch(e, "Send Foreach Data error. Response ", "Send Foreach Data error. ");
  }
}

export async function buildStats() {
  let response;
  try {
    response = await http().get(`/api/proactive/buildStats`);
    return response.data;
  } catch (e) {
    showCatch(e, "Build Stats Proactive Sourcing error. Response ", "Build Stats Proactive Sourcingerror. ");
  }
}

export async function setReferenceToProactiveTable(JDId: string, proactiveSourcingId: string, branchId: string) {
  let response;
  try {
    response = await http().post(`/api/proactive/setReferences`, { JDId, proactiveSourcingId, branchId });
    return response.data;
  } catch (e) {
    showCatch(e, "Build Stats Proactive Sourcing error. Response ", "Build Stats Proactive Sourcingerror. ");
  }
}

export async function getForecastData(filters: FiltersResults, branchId: string) {
  const { page, perPage: size, search, sortBy, sortDirection } = filters;
  let response;
  const params = { page, size, search, sortBy, sortDirection, branchId };
  try {
    response = await http().get(`/api/proactive/forecastData`, { params: params });
    return response;
  } catch (e) {
    showCatch(e, "Get Forecast Data Proactive Sourcing error. Response ", "Get Forecast Data Proactive Sourcingerror. ");
  }
}

export async function updateProcessUrls(processId: string, urls: any[]) {
  let response;
  try {
    response = await http().put(`/api/processes/${processId}/urls`, { urls });
    return response.data;
  } catch (e) {
    showCatch(e, "Update process urls error. Response ", "Update process urls error. ");
  }
}

export async function updateJobDescriptionUrls(jobDescriptionId: string, urls: any[]) {
  let response;
  try {
    response = await http().put(`/api/jobDescription/${jobDescriptionId}/urls`, { urls });
    return response.data;
  } catch (e) {
    showCatch(e, "Update Job Description urls error. Response ", "Update Job Description urls error. ");
  }
}

export async function getAllCandidatesUtm(processId: string) {
  let response;
  try {
    response = await http().get(`/api/processes/${processId}/candidates/utm`);
    return response.data;
  } catch (e) {
    showCatch(e, "Update process candidates utm error. Response ", "Update process candidates utm error. ");
  }
}
export async function getAvailabilityInfo(calendarIds: string[]) {
  try {
    let response;
    response = await http().post("/api/calendars/getAvailabilityInfo", { calendarIds });
    return response.data;
  } catch (e) {
    showCatch(e, "Calendar error. Response ", "Calendar error. ");
  }
}
export async function getUserIdByEmail(email: string) {
  let response;
  try {
    response = await http().get("/api/users/" + email + "/id");
    return response.data;
  } catch (e) {
    showCatch(e, "Get user id by email not found. Response ", "Get user id by email not found. error ");
  }
}

export async function getAllPersonCandidates(externalUserId?: any, peronId?: any, candidateId?: any) {
  try {
    let response;
    const params = { externalUserId, peronId, candidateId };
    response = await http().get("/api/persons/candidates", { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Person candidates error. Response ", "Person candidates error. ");
  }
}
export async function getCandidateAttachment(tenantAlias: string, personId: string, fileId: string) {
  let response;
  const params = { tenantAlias, personId, fileId };
  try {
    response = await http().get(`/api/candidate/incorporation/attachment`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get attachment not found. Response ", "Get attachment not found. error ");
  }
}
export async function getFavouriteProcess() {
  let response;
  try {
    response = await http().get(`/api/processes/favourite`);
    return response.data;
  } catch (e) {
    showCatch(e, "Favourite process get error. Response ", "Favourite process get error. ");
  }
}
export async function addFavouriteProcess(processId: string) {
  try {
    let response;
    response = await http().post(`/api/processes/${processId}/favourite`);
    return response.data;
  } catch (e) {
    showCatch(e, "Favourite process post error. Response ", "Favourite process post error. ");
  }
}
export async function removeFavouriteProcess(processId: string) {
  let response;
  try {
    response = await http().delete(`/api/processes/${processId}/favourite`);
    return response.data;
  } catch (e) {
    showCatch(e, "Favourite process delete error. Response ", "Favourite process delete error. ");
  }
}

export async function resentEvent(id: any, isFromFE?: boolean) {
  let response;
  try {
    response = await http().post(`/api/activity/resendEventBusMessage`, { id, isFromFE });
    return response.data;
  } catch (e) {
    showCatch(e, "Resend event bus error. Response ", "Resend event bus error. ");
  }
}

export async function createMediaFromTemplate(phases: ProcessPhase[], isCopy: boolean, templateId?: string, oldId?: string) {
  let response;
  try {
    response = await http().post(`/api/processes/media`, { phases: phases, templateId: templateId, isCopy: isCopy, oldId: oldId });
    return response.data;
  } catch (e) {
    showCatch(e, "Resend event bus error. Response ", "Resend event bus error. ");
  }
}

// SMS
export async function getAllTenantSMSVariables(filters: FiltersResults) {
  const { page, perPage, search, sortBy, sortDirection } = filters;
  const params = {
    page,
    perPage,
    search,
    sortBy,
    sortDirection,
  };
  if (search === "") delete params.search;
  try {
    return await http().get("/api/sms/tenantSmsTemplateVars", { params: params });
  } catch (e) {
    showCatch(e, "Get Tenant Template Vars. Response ", "Get Tenant Template Vars. ");
  }
}

export async function createTenantSMSVariable(payload: TenantSMSVars) {
  try {
    return await http().post("/api/sms/tenantSmsTemplateVars", payload);
  } catch (e) {
    showCatch(e, "Create Tenant Template Var.  Response ", "Create Tenant Template Var. . ");
  }
}

export async function updateTenantSMSVariable(payload: TenantSMSVars, id: string) {
  try {
    return await http().put(`/api/sms/tenantSmsTemplateVars/${id}`, payload);
  } catch (e) {
    showCatch(e, "Update Tenant Template Var.  Response ", "Update Tenant Template Var. . ");
  }
}

export async function deleteTenantSMSVariable(id: string) {
  try {
    return await http().delete(`/api/sms/tenantSmsTemplateVars/${id}`);
  } catch (e) {
    showCatch(e, "Delete Tenant Template Var.  Response ", "Delete Tenant Template Var. . ");
  }
}

export async function getAllNotificationTemplatesPaginated(filters: FiltersResults) {
  const { page, perPage, search, sortBy, sortDirection } = filters;
  const params = {
    page,
    perPage,
    search,
    sortBy,
    sortDirection,
  };
  if (search === "") delete params.search;
  try {
    return await http().get("/api/notification/notificationTemplates", { params: params });
  } catch (e) {
    showCatch(e, "Get Notification Template . Response ", "Get Notification Template. ");
  }
}

export async function getAllNotificationTemplates(filters: FiltersResults) {
  const { search, sortBy, sortDirection } = filters;
  const params = { search, sortBy, sortDirection };
  if (search === "") delete params.search;
  try {
    return await http().get("/api/notification/notificationTemplates/all", { params: params });
  } catch (e) {
    showCatch(e, "Get All Notification Template . Response ", "Get All Notification Template. ");
  }
}

export async function getAllSMSCandidateStatus(filters: FiltersResults) {
  const { page, perPage, candidate, process, sortBy, sortDirection, status } = filters;
  const params = {
    page,
    perPage,
    candidate,
    process,
    sortBy,
    sortDirection,
    status,
  };
  if (params.process === "" || params.process === undefined) delete params.process;
  try {
    return await http().get("/api/sms/candidateStatus", { params: params });
  } catch (e) {
    showCatch(e, "Get SMS Candidate Status . Response ", "Get SMS Candidate Status. ");
  }
}

export async function getGDSPEvents(payload: any) {
  try {
    return await http().post("/api/externalSystems/GDSPEvents", { payload });
  } catch (e) {
    showCatch(e, "Get SMS Template . Response ", "Get SMS Template. ");
  }
}

export async function createNotificationTemplate(payload: TenantSMSVars) {
  try {
    if (payload.variables) delete payload.variables;
    return await http().post("/api/notification/notificationTemplate", payload);
  } catch (e) {
    showCatch(e, "Create Notification Template.  Response ", "Create Notification Template. . ");
  }
}

export async function updateNotificationTemplate(payload: TenantSMSVars, id: string) {
  try {
    return await http().put(`/api/notification/notificationTemplate/${id}`, payload);
  } catch (e) {
    showCatch(e, "Update Notification Template.  Response ", "Update Notification Template. . ");
  }
}

export async function deleteNotificationTemplate(id: string) {
  try {
    return await http().delete(`/api/notification/notificationTemplate/${id}`);
  } catch (e) {
    showCatch(e, "Delete Notification Template.  Response ", "Delete Notification Template. . ");
  }
}
export async function getJobDescriptionBoards(branchId: string, filters?: FiltersResults) {
  let response;
  try {
    let subFilt = filters?.search ? `?search=${filters?.search}` : "";
    response = await http().get(`/api/admin/jobDescriptionBoards/branch/${branchId}${subFilt}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Job description board get error. Response ", "Job description board get error. ");
  }
}

export async function uploadJobDescriptionBoard(contractType: ContractType) {
  let response;
  try {
    response = await http().post("/api/admin/jobDescriptionBoards", { ...contractType });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch Job description board error. Response ", "fetch Job description board error. ");
  }
}

export async function updateJobDescriptionBoard(jobDescriptionBoard: JobDescriptionBoard) {
  if (jobDescriptionBoard.notCompatible === null || jobDescriptionBoard.notCompatible === undefined) delete jobDescriptionBoard.notCompatible;
  if (jobDescriptionBoard.defaultPeriods === null || jobDescriptionBoard.defaultPeriods === undefined) delete jobDescriptionBoard.defaultPeriods;
  if (jobDescriptionBoard.customFields === null || jobDescriptionBoard.customFields === undefined) delete jobDescriptionBoard.customFields;
  if (jobDescriptionBoard.utmFields === null || jobDescriptionBoard.utmFields === undefined) delete jobDescriptionBoard.utmFields;
  let response;
  try {
    response = await http().put("/api/admin/jobDescriptionBoards/" + jobDescriptionBoard.id, { ...jobDescriptionBoard });
    return response.data;
  } catch (e) {
    showCatch(e, "fetch Job description board error. Response ", "fetch Job description board error. ");
  }
}

export async function deleteJobDescriptionBoard(jobDescriptionBoardId: string) {
  let response;
  try {
    response = await http().delete("/api/admin/jobDescriptionBoards/" + jobDescriptionBoardId);
    return response.data;
  } catch (e) {
    showCatch(e, "fetch Job description board error. Response ", "fetch Job description board error. ");
  }
}

export async function getBudgets(params: any) {
  let response;
  try {
    response = await http().get(`api/budgets`, { params });
    return response.data;
  } catch (e) {
    showCatch(e, "Budgets get error. Response ", "Budgets get error. ");
  }
}

export async function getAllBudgets() {
  let response;
  try {
    response = await http().get(`api/budgets/all`);
    return response.data;
  } catch (e) {
    showCatch(e, "Budgets get error. Response ", "Budgets get error. ");
  }
}

export async function getBudgetsByBranchId(id: string) {
  let response;
  try {
    response = await http().get(`api/budgets/branch/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Budgets get error. Response ", "Budgets get error. ");
  }
}

export async function updateBudgets(budgetBranches: any[]) {
  let response;
  try {
    response = await http().put(`api/budgets`, { budgetBranches });
    return response.data;
  } catch (e) {
    showCatch(e, "Budgets update error. Response ", "Budgets update error. ");
  }
}

export async function updateSingleBudget(id: string, budgetBranches: Object) {
  let response;
  try {
    response = await http().put(`api/budgets/${id}`, { ...budgetBranches });
    addNotification({ type: "success", content: toLiteral({ id: "admin.budget.successMessage" }), timer: 3 });
    return response.data;
  } catch (e) {
    addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 3 });
    showCatch(e, "Budgets update error. Response ", "Budgets update error. ");
  }
}

export async function resetSingleBudget(id: string) {
  let response;
  try {
    response = await http().post(`api/budget/reset/${id}`);
    addNotification({ type: "success", content: toLiteral({ id: "Budget reset successfully" }), timer: 3 });
    return response.data;
  } catch (e) {
    addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 3 });
    showCatch(e, "Budgets reset error. Response ", "Budgets delete error. ");
  }
}

export async function getTenantBudgetBranches(tenantId: number) {
  let response;
  try {
    response = await http().get(`api/budgets/branches/${tenantId}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Budgets branches error. Response ", "Budgets branches error. ");
  }
}

export async function redirectSMSShortUrl(id: string) {
  let response;
  try {
    response = await http().get(`api/sms/redirectUrl/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "SMS Redirect error. Response ", "SMS Redirect error. ");
  }
}

export async function fetchLiveInterviewEvaluationQuestions(candidateId: string, phaseIndex: number) {
  let response;

  try {
    response = await http().get(`/api/candidates/${candidateId}/liveInterview/${phaseIndex}/questions`);
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch Live Interview Questions error. Response ", "Fetch Live Interview Questionserror. ");
  }
}

export async function putUserProperties(userProperties: UserProperties) {
  let response;
  try {
    response = await http().put("/api/users/properties", userProperties);
    return response.data;
  } catch (e) {
    showCatch(e, "putDisableSpam  error. Response ", "putDisableSpam error. ");
  }
}

export async function exportTenantSettings() {
  let response;
  try {
    response = await http().get("/api/exportFeatureConfigurations");
    return response.data;
  } catch (e) {
    showCatch(e, "exportTenantSettings error. Response ", "exportTenantSettings error. ");
  }
}

export async function importTenantSettings(featureConfigurations: FeatureConfigurationType[]) {
  let response;
  try {
    response = await http().post("/api/importFeatureConfigurations", { featureConfigurations });
    return response.data;
  } catch (e) {
    showCatch(e, "exportTenantSettings error. Response ", "exportTenantSettings error. ");
  }
}

export async function getUserFeedback() {
  let response;
  try {
    response = await http().get(`/api/user/feedback`);
    return response.data;
  } catch (e) {
    showCatch(e, "User feedback get error. Response ", "User feedback get error. ");
  }
}

export async function postUserFeedback(feedback: UserFeedback) {
  let response;
  try {
    response = await http().post("/api/user/feedback", { ...feedback });
    return response.data;
  } catch (e) {
    showCatch(e, "Post User feedback error. Response ", "Post User feedback error. ");
  }
}

export async function getGlobalAdminUserFeedback(filters: FiltersResults) {
  const params = {
    ...filters,
  };
  if (params.search === "" || params.search === undefined) delete params.search;
  if (params.dateRange === "" || params.dateRange === undefined) delete params.dateRange;

  if (params.dateRange && Array.isArray(params.dateRange) && params.dateRange.length === 2) {
    params.dateRange = params.dateRange[0] + "," + params.dateRange[1];
  }

  let response;
  try {
    response = await http().get(`/api/user/feedback/admin`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Admin User feedback get error. Response ", "Admin User feedback get error. ");
  }
}

export async function getAdminUserFeedbackNonPaginated(filters: FiltersResults) {
  const params = { ...filters };
  if (params.search === "" || params.search === undefined) delete params.search;
  if (params.dateRange === "" || params.dateRange === undefined) delete params.dateRange;
  if (params.dateRange && Array.isArray(params.dateRange) && params.dateRange.length === 2) {
    params.dateRange = params.dateRange[0] + "," + params.dateRange[1];
  }
  let response;
  try {
    response = await http().get(`/api/user/feedbackAll/admin`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Admin User feedback get all error. Response ", "Admin User feedback get all error. ");
  }
}

export async function getLocalAdminUserFeedback(filters: FiltersResults) {
  const params = { ...filters };
  if (params.search === "" || params.search === undefined) delete params.search;
  if (params.dateRange === "" || params.dateRange === undefined) delete params.dateRange;
  if (params.dateRange && Array.isArray(params.dateRange) && params.dateRange.length === 2) {
    params.dateRange = params.dateRange[0] + "," + params.dateRange[1];
  }
  let response;
  try {
    response = await http().get(`/api/user/tenantFeedback/admin`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Admin User feedback get error. Response ", "Admin User feedback get error. ");
  }
}

export async function getLocalAdminUserFeedbackNonPaginated(filters: FiltersResults) {
  const params = { ...filters };
  if (params.search === "" || params.search === undefined) delete params.search;
  if (params.dateRange === "" || params.dateRange === undefined) delete params.dateRange;
  if (params.dateRange && Array.isArray(params.dateRange) && params.dateRange.length === 2) {
    params.dateRange = params.dateRange[0] + "," + params.dateRange[1];
  }
  let response;
  try {
    response = await http().get(`/api/user/tenantFeedbackAll/admin`, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Admin User feedback get all error. Response ", "Admin User feedback get all error. ");
  }
}

export async function cancelInterview(candidateId: string, phase: number, phaseCancelled: PhaseCancelled) {
  let response;
  try {
    response = await http().post(`/api/candidates/${candidateId}/phaseIndex/${phase}/cancelInterview`, phaseCancelled);
    return response.data;
  } catch (e) {
    showCatch(e, "Cancel interview. Response ", "Cancel interview error. ");
  }
}

export async function fetchRecruitersModules() {
  let response;
  try {
    response = await http().get("/api/recruiterProfileModule");

    return response.data;
  } catch (e) {
    showCatch(e, "Get Recruiters modules error. Response ", "Get Recruiters modules Attribute error. ");
  }
}

export async function fetchRecruitersModulesAllowed() {
  let response;
  try {
    response = await http().get("/api/recruiterProfileModuleAllowed");

    return response.data;
  } catch (e) {
    showCatch(e, "Get Recruiters modules allowed error. Response ", "Get Recruiters modules allowed Attribute error. ");
  }
}

export async function postRecruiterModules(params: any) {
  let response;
  try {
    response = await http().post("/api/recruiterProfileModule", params);
    return response.data;
  } catch (e) {
    showCatch(e, "Post Recruiters modules error. Response ", "Post Recruiters modules error. ");
  }
}

export async function putRecruiterModules(id: string, params: any) {
  let response;
  try {
    response = await http().put(`/api/recruiterProfileModule/${id}`, params);

    return response.data;
  } catch (e) {
    showCatch(e, "Update Recruiters modules error. Response ", "Update Recruiters modules error. ");
  }
}

export async function deleteRecruiterModules(id: string) {
  let response;
  try {
    response = await http().delete(`/api/recruiterProfileModule/${id}`);

    return response.data;
  } catch (e) {
    showCatch(e, "Delete Recruiters modules error. Response ", "Delete Recruiters modules error. ");
  }
}

export const fetchDivisions = async (): Promise<[Division[], number] | undefined> => {
  try {
    return (await http().get("/api/division")).data;
  } catch (e) {
    showCatch(e, "Division List error. Response ", "Division List error. ");
  }
};

export const createDivision = async (name: string): Promise<Division | undefined> => {
  try {
    return (await http().post("api/division", { name })).data;
  } catch (e) {
    showCatch(e, "Division create error. Response ", "Division create error.");
  }
};

export const updateDivision = async (divisionId: string, division: Division): Promise<Division | undefined> => {
  try {
    return (await http().put(`api/division/${divisionId}`, { division })).data;
  } catch (e) {
    showCatch(e, "Division create error. Response ", "Division create error.");
  }
};

export const deleteDivision = async (divisionId: string): Promise<Division | undefined> => {
  try {
    return (await http().delete(`api/division/${divisionId}`)).data;
  } catch (e) {
    if (errorIsForeignConstraintError(e)) addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.divisionIsInUse" }), timer: 5 });
    else addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 5 });
  }
};

export const fetchBusinessLines = async (): Promise<[BusinessLine[], number] | undefined> => {
  try {
    return (await http().get("/api/businessLine")).data;
  } catch (e) {
    showCatch(e, "BusinessLine List error. Response ", "BusinessLine List error.");
  }
};

export const createBusinessLine = async (name: string): Promise<BusinessLine | undefined> => {
  try {
    return (await http().post("api/businessLine", { name })).data;
  } catch (e) {
    showCatch(e, "BusinessLine create error. Response ", "BusinessLine create error.");
  }
};

export const updateBusinessLine = async (businessLineId: string, businessLine: BusinessLine): Promise<BusinessLine | undefined> => {
  try {
    return (await http().put(`api/businessLine/${businessLineId}`, { businessLine })).data;
  } catch (e) {
    showCatch(e, "BusinessLine update error. Response ", "BusinessLine update error. ");
  }
};

export const deleteBusinessLine = async (businessLineId: string): Promise<BusinessLine | undefined> => {
  try {
    return (await http().delete(`api/businessLine/${businessLineId}`)).data;
  } catch (e) {
    if (errorIsForeignConstraintError(e)) addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.businessLineIsInUse" }), timer: 5 });
    else addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 5 });
  }
};

export const fetchClientBrands = async (): Promise<[ClientBrand[], number] | undefined> => {
  try {
    return (await http().get("/api/clientBrand")).data;
  } catch (e) {
    showCatch(e, "ClientBrand List error. Response ", "ClientBrand List error.");
  }
};

export const createClientBrand = async (clientBrand: NewClientBrandData, primaryLogoFile: File, secondaryLogoFile: File): Promise<ClientBrand | undefined> => {
  let formData = new FormData();
  formData = appendFileData(formData, primaryLogoFile, "asd", "PrimaryLogo");
  formData = appendFileData(formData, secondaryLogoFile, "asd", "SecondaryLogo");
  formData.set("clientBrand", JSON.stringify(clientBrand));
  formData.set("contentTypePrimaryLogo", primaryLogoFile.type);
  formData.set("contentTypeSecondaryLogo", secondaryLogoFile.type);
  if (clientBrand?.emailHeaderImage) {
    formData = appendFileData(formData, clientBrand?.emailHeaderImage, "asd", "emailHeaderImage");
    formData.set("contentTypeEmailHeaderImage", clientBrand.emailHeaderImage.type);
  }

  try {
    return (await http().post("api/clientBrand", formData)).data;
  } catch (e) {
    showCatch(e, "ClientBrand create error. Response ", "ClientBrand create error.");
  }
};

export const updateClientBrand = async (clientBrandId: string, clientBrand: ClientBrand, primaryLogoFile?: File, secondaryLogoFile?: File): Promise<ClientBrand | undefined> => {
  let formData = new FormData();
  if (primaryLogoFile) {
    formData = appendFileData(formData, primaryLogoFile, "asd", "PrimaryLogo");
    formData.set("contentTypePrimaryLogo", primaryLogoFile.type);
  }
  if (secondaryLogoFile) {
    formData = appendFileData(formData, secondaryLogoFile, "asd", "SecondaryLogo");
    formData.set("contentTypeSecondaryLogo", secondaryLogoFile.type);
  }
  if (clientBrand?.emailHeaderImage) {
    formData = appendFileData(formData, clientBrand.emailHeaderImage, "asd", "emailHeaderImage");
    formData.set("contentTypeEmailHeaderImage", clientBrand.emailHeaderImage.type);
  }
  formData.set("clientBrand", JSON.stringify(clientBrand));
  try {
    return (await http().put(`api/clientBrand/${clientBrandId}`, formData)).data;
  } catch (e) {
    showCatch(e, "ClientBrand update error. Response ", "ClientBrand update error. ");
  }
};

export const deleteClientBrand = async (clientBrandId: string): Promise<ClientBrand | undefined> => {
  try {
    return (await http().delete(`api/clientBrand/${clientBrandId}`)).data;
  } catch (e) {
    if (errorIsForeignConstraintError(e)) addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.clientBrandIsInUse" }), timer: 5 });
    else addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 5 });
  }
};

export async function checkNameTemplateDuplicate(name: string, isGlobal?: boolean) {
  let response;
  try {
    response = await http().get(`/api/processTemplate/checkAvailability?name=${name}&isGlobal=${!!isGlobal}`);
    return response.data;
  } catch (e) {
    showCatch(e, "CheckAvailability template name error. Response ", "CheckAvailability template name error. ");
  }
}

export async function getAllLiterals(language = "all", method = "legacy") {
  try {
    const response = await http().get(`/api/literal/all?language=${language}&method=${method}`);
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

// Logic to force refresh cache
export async function checkShouldRefresh(language: string): Promise<boolean> {
  try {
    let refresh = false;
    const response = await http().get(`/api/literal/all/${language}/check`);
    const key = response?.data?.toString();
    const oldKey = localStorage.getItem(`literals-${language}`);
    if (key !== "null" && key !== oldKey) {
      localStorage.setItem(`literals-${language}`, key);
      refresh = true;
    }
    return refresh;
  } catch (e) {
    console.error(e);
    return false;
  }
}
export async function getAllLiteralsByLanguage(language: string, method = "legacy", checkForceRefresh = false) {
  const forceRefresh = checkForceRefresh && (await checkShouldRefresh(language));
  const headers = forceRefresh ? { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" } : {};
  try {
    const response = await http(false, headers).get(`/api/literal/all/${language}?method=${method}`);
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function getAllLiteralsPaginated(_request: any) {
  const { language, page, size, filter, order } = _request;
  try {
    const response = await http().get(`/api/literal?language=${language || "all"}&page=${page}&size=${size}&filter=${filter}&order=${order}`);
    return response?.data;
  } catch (e) {
    return e;
  }
}

export async function postLiteral(_request: any) {
  try {
    const response = await http().post(`/api/literal`, _request);
    return response?.data;
  } catch (e) {
    return e;
  }
}
export async function putLiteral(_request: any) {
  try {
    const response = await http().put(`/api/literal`, _request);
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}
export async function deleteLiteral(_id: string, deleteAll = false) {
  try {
    const response = await http().delete(`/api/literal?id=${_id}`, { data: { deleteAll } });
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function importLiterals(_request: any) {
  try {
    const response = await http().put(`/api/literal/import`, _request);
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function getAppointmentsByRequisitionId(requisitionId: string) {
  try {
    return await http().get(`/api/externalSystems/RCAppointmentsEvents/${requisitionId}`);
  } catch (e) {
    showCatch(e, "Get appointments by requisitionId error", '"Get appointments by requisitionId error"');
  }
}

export async function getInfoCandidateByExternalId(externalCandidateId: string, externalId: string, brandName: string, isoCountryCode: string, externalType?: string) {
  const params = externalType ? { type: externalType } : {};
  let response;
  try {
    response = await http().get("/api/candidates/external/" + externalCandidateId + "/process/" + externalId + "/countries/" + isoCountryCode + "/brands/" + brandName, { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Get process by externalId. Response ", "Get process by externalId. error ");
  }
}

export async function fetchSectionsCategories() {
  let response;
  try {
    response = await http().get("/api/jobDescription/sectionCategories");
    return response.data;
  } catch (e) {
    showCatch(e, "Get Sections categories. Response ", "Get Sections categories. error ");
  }
}

export async function addSectionCategory(sectionCategory: JobDescriptionSectionCategory) {
  try {
    let response;
    response = await http().post(`/api/jobDescription/sectionCategories`, sectionCategory);
    return response.data;
  } catch (e) {
    showCatch(e, "Section category post error. Response ", "Section category process post error. ");
  }
}

export async function updateSectionCategory(sectionCategory: JobDescriptionSectionCategory) {
  try {
    let response;
    response = await http().post(`/api/jobDescription/sectionCategories/${sectionCategory.id}`, sectionCategory);
    return response.data;
  } catch (e) {
    showCatch(e, "Section category update error. Response ", "Section category process update error. ");
  }
}
export async function deleteSectionCategory(sectionCategory: JobDescriptionSectionCategory) {
  try {
    let response;
    response = await http().delete(`/api/jobDescription/sectionCategories/${sectionCategory.id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Section category delete error. Response ", "Section category process delete error. ");
  }
}

//* Custom Global Operational Recruiters Report
//Api e.p for custom report (POC Refactor)
export async function fetchCustomOperationalRecruitersReportGlobal(initDate: number, endDate: number, role?: string, filters?: FiltersResults, cb?: Function) {
  const params = { initDate, endDate, role, ...filters };
  let response;
  try {
    response = await http().get("/api/recruiterCustomReportsGlobal", { params: params });
    if (cb) cb();
    return response.data;
  } catch (e) {
    showCatch(e, "Process operational report global error. Response ", "Process operational report global error. ");
  }
}

export async function fetchReportStatsByTenant(initDate: number, endDate: number, role?: string, cb?: Function) {
  const params = { initDate, endDate, role };
  let response;
  try {
    response = await http().get("/api/getTenantCustomReportLive", { params: params });
    if (cb) cb();
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant report stats error. Response ", "Tenant report stats error. ");
  }
}

export async function fetchCustomOperationalRecruitersReportPerTenant(initDate: number, endDate: number, tenantId?: number, branchId?: string, filters?: FiltersResults, cb?: Function) {
  const params = { initDate, endDate, tenantId, branchId, ...filters };
  let response;
  try {
    response = await http().get("/api/recruiterReportsPerTenant", { params: params });
    if (cb) cb();
    return response.data;
  } catch (e) {
    showCatch(e, "Process operational report global error. Response ", "Process operational report global error. ");
  }
}

//E.P set state registration (Enhacement stats)
export async function updateStateRegistrationStats(candidateId: string, type: number, phaseIndex: number, status: string) {
  let response;
  try {
    response = await http().post("/api/stats/setStateRegistration", { candidateId, data: { index: phaseIndex, status, type } });
    return response.data;
  } catch (e) {
    showCatch(e, "UpdateStateRegistrationStats. Response ", "UpdateStateRegistrationStats ");
  }
}

export async function getStatsRegistration(defaultParams: { year: string; month: string; tenantNames: any; typeDate: string; tenants: { id: string | number; name: string }[] }) {
  const { tenants, typeDate, month, year } = defaultParams;
  let { tenantNames } = defaultParams;
  let tenantString;
  if (tenants?.length && Array.isArray(tenantNames)) {
    tenantNames = tenantNames.filter((name) => name !== "Select all")?.map((name: string) => tenants.find((tenant) => tenant?.name === name)?.id);
  }
  if (Array.isArray(tenantNames)) {
    tenantString = tenantNames.join(", ");
  } else {
    tenantString = tenants?.find((tenant) => tenant?.name === tenantNames)?.id || parseInt(tenantNames);
  }
  const params = { tenantIds: tenantString, typeDate, month, year };
  let response;
  try {
    response = await http().get("/api/getTenantStats", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "GetStatsRegistration. Response ", "GetStatsRegistration ");
    return `GetStatsRegistration.fail: ${e}`;
  }
}

export async function sendWelcomeSmsTest(welcomeMessagePayload: SendEmailWelcomeMessage) {
  let response;
  try {
    response = await http().post("/api/sendWelcomeSmsTest", welcomeMessagePayload);
    return response;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

/**
 * Function that fetches the cookie banner
 */
export async function fetchCookieBanner() {
  let response;
  try {
    response = await http().get("/api/cookieBanner/tenant");
    return response.data;
  } catch (e) {
    showCatch(e, "Cookie banner response. Response ", "Cookie banner response. ");
  }
}

export async function postCookieBanner(html: any, update?: boolean, id?: string) {
  let response;
  try {
    if (update) {
      response = await http().put(`/api/cookieBanner/${id}`, html);
    } else {
      response = await http().post("/api/cookieBanner", html);
    }
    return response.data;
  } catch (e) {
    showCatch(e, "Cookie banner response. Response ", "Cookie banner response. ");
  }
}

export async function fetchLeads(page: number, perPage: number, search: string, order: string) {
  const params = { page, perPage, search, orderBy: order };
  let response;
  try {
    response = await http().get("/api/leads/all", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Leads error. Response ", "Leads error. ");
  }
}

export async function fetchLead(id: string) {
  let response;
  try {
    response = await http().get(`/api/leads/${id}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Lead error. Response ", "Lead error. ");
  }
}

export async function fetchLeadNotificationResume(leadId: string) {
  let response;
  try {
    response = await http().get(`/api/lead/resume/${leadId}`);
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch Lead Notification error. Response ", "Fetch Lead Notification error. ");
  }
}

export async function updateLead(leadIds: string[], processId: string) {
  const params = { leadIds, processId };
  let response;
  try {
    response = await http().put("/api/leads/process", { ...params });
    return response.data;
  } catch (e) {
    showCatch(e, "Lead error. Response ", "Lead error. ");
  }
}

export async function addLeadAsCandidate(leadId: string, body: any) {
  let response;
  try {
    response = await http().post("/api/leads/" + leadId + "/registration", { ...body });
    return response.data;
  } catch (e) {
    showCatch(e, "Lead error. Response ", "Lead error. ");
  }
}

export async function rejectLeadRegistration(leadId: string) {
  let response;
  try {
    response = await http().put("/api/leads/unsubscribe/" + leadId);
    return response.data;
  } catch (e) {
    showCatch(e, "Lead error. Response ", "Lead error. ");
  }
}

export async function setNewNotificationToLead(message: string, leads: string[], type: string) {
  let response;
  try {
    response = await http().post(`/api/lead/notification`, { message, leads, type });
    return response.data;
  } catch (e) {
    showCatch(e, "Set New Notification To Lead error. Response ", "Set New Notification To Lead error. ");
  }
}
export async function runInactiveLeadsDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/inactiveLeads/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Inactive Leads Data Retention error. Response ", "Run Inactive Leads Data Retention error. ");
  }
}
export async function runLeadRejectionsDataRetention() {
  let response;
  try {
    response = await http().get("/api/dataRetention/leadRejections/run");

    return response.data;
  } catch (e) {
    showCatch(e, "Run Lead Rejections Data Retention error. Response ", "Run Lead Rejections Data Retention error. ");
  }
}
export async function addPublicCandidate(processId: string, candidateId: string, candidateData: any) {
  const path = "/api/processes/" + processId + "/candidates/" + candidateId;
  const response = await http().post(path, candidateData);
  return response.data;
}

export async function clearCache() {
  let response;
  try {
    response = await http().post("/api/externalSystems/redis/flush");
    return response;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function receiveEvent(eventPayload: any) {
  let response;
  try {
    response = await http().post("/api/integrations/mockevents", JSON.parse(eventPayload));
    return response;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function sendEvent(providerName: string, topic: string, messageId: string, queueMessage: string, applicationProperties: string) {
  let response;
  try {
    response = await http().post("/api/activity/manualSendEventBusMessage", {
      providerName,
      topic,
      messageId,
      queueMessage: JSON.parse(queueMessage),
      applicationProperties: JSON.parse(applicationProperties),
    });
    addNotification({ type: "success", content: toLiteral({ id: "eventsSend.requestSuccess" }), timer: 3 });
    return response;
  } catch (e) {
    addNotification({ type: "error", content: toLiteral({ id: "eventsSend.requestError" }), timer: 3 });
  }
}

export async function getJDBUTenantsManager() {
  try {
    return await http().get("/api/cs/jobDescriptionBUTenantManager");
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getJDBUTenantPasswordDecrypted(password: string) {
  try {
    return await http().get(`/api/cs/jobDescriptionBUTenant/password/decrypt/${password}`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getJDBUTenantPasswordEncrypted(password: string) {
  try {
    return await http().post(`/api/cs/jobDescriptionBUTenant/password/encrypt/${password}`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function putJDBUTenantManager(id: number, data: any) {
  try {
    return await http().put(`/api/cs/jobDescriptionBUTenantManager/${id}`, data);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function downloadUSA() {
  try {
    return await http().get(`/api/candidateReportUSA`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function fetchAdditionalDocuments(order?: string, filter?: string) {
  const params = { order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/additionalDocuments/all", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Additional Documents error. Response ", "Additional documents error. ");
  }
}

export async function postAdditionalDocument(payload: AdditionalDocuments) {
  try {
    return await http().post("/api/additionalDocuments", payload);
  } catch (e) {
    showCatch(e, "Additional document error. Response ", "Additional document error. ");
  }
}

export async function updateAdditionalDocument(payload: AdditionalDocuments, id: string) {
  try {
    return await http().put(`/api/additionalDocuments/${id}`, payload);
  } catch (e) {
    showCatch(e, "Upload additional document. Response ", "Upload additional document. ");
  }
}

export async function deleteAdditionalDocument(id: string) {
  try {
    return await http().delete(`/api/additionalDocuments/${id}`);
  } catch (e) {
    showCatch(e, "Delete additional document. Response ", "Delete additional document. ");
  }
}

export async function fetchTenantScripts(order?: string, filter?: string) {
  const params = { order, filter } as any;
  if (params.filter === null || params.filter === undefined || params.filter === "") delete params.filter;
  let response;
  try {
    response = await http().get("/api/tenantScripts/all", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Tenant Scripts error. Response ", "Tenant Scripts error. ");
  }
}

export async function postTenantScripts(payload: any) {
  try {
    return await http().post("/api/tenantScripts", payload);
  } catch (e) {
    showCatch(e, "Tenant Scripts error. Response ", "Tenant Scripts error. ");
  }
}

export async function updateTenantScripts(id: string, payload: any) {
  try {
    return await http().put(`/api/tenantScripts/${id}`, payload);
  } catch (e) {
    showCatch(e, "Upload Tenant Script. Response ", "Upload Tenant Script. ");
  }
}

export async function deleteTenantScript(id: string) {
  try {
    return await http().delete(`/api/tenantScripts/${id}`);
  } catch (e) {
    showCatch(e, "Delete Tenant Script. Response ", "Delete Tenant Script. ");
  }
}

export async function fetchGeneratedDocument(formData: any, tenantId: number, cancelToken: any) {
  try {
    const response = await http(undefined, undefined, cancelToken).post(`/jdgenerator/${tenantId}`, formData);
    return response.data;
  } catch (e) {
    showCatch(e, "Process Details error. Response ", "Process Details error. ");
  }
}

export async function fetchConversionRatespaginated(search?: string, searchType?: string, page?: number, applicationDate?: number, client?: string, optedOut?: string, candidateSource?: number) {
  let params = { search, searchType, page, applicationDate, client, optedOut, candidateSource } as any;
  if (params.search === null || params.search === undefined || params.search === "") delete params.search;
  if (params.searchType === null || params.searchType === undefined || params.searchType === "") delete params.searchType;
  if (params.page === null || params.page === undefined || params.page === "") delete params.page;
  if (params.applicationDate === null || params.applicationDate === undefined || params.applicationDate === "") delete params.applicationDate;
  if (params.client === null || params.client === undefined || params.client === "") delete params.client;
  if (params.optedOut === null || params.optedOut === undefined || params.optedOut === "") delete params.optedOut;
  if (params.candidateSource === null || params.candidateSource === undefined || params.candidateSource === "") delete params.candidateSource;

  let response;
  try {
    response = await http().get("/api/conversionRates/allPaginated", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Conversion Rates error. Response ", "Conversion Rates error. ");
  }
}

export async function fetchConversionRates(search?: string, searchType?: string, applicationDate?: number, client?: string, optedOut?: string, candidateSource?: string) {
  let params = { search, searchType, applicationDate, client, optedOut, candidateSource } as any;
  if (params.search === null || params.search === undefined || params.search === "") delete params.search;
  if (params.searchType === null || params.searchType === undefined || params.searchType === "") delete params.searchType;
  if (params.applicationDate === null || params.applicationDate === undefined || params.applicationDate === "") delete params.applicationDate;
  if (params.client === null || params.client === undefined || params.client === "") delete params.client;
  if (params.optedOut === null || params.optedOut === undefined || params.optedOut === "") delete params.optedOut;
  if (params.candidateSource === null || params.candidateSource === undefined || params.candidateSource === "") delete params.candidateSource;

  let response;
  try {
    response = await http().get("/api/conversionRates/all", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Conversion Rates error. Response ", "Conversion Rates error. ");
  }
}

export async function sendJDIdsToCreateManually(ids: string[]) {
  let response;
  try {
    response = await http().post("/api/jobDescription/uploadJDPsManually", { ids });
    return response.data;
  } catch (e) {
    showCatch(e, "Conversion Rates error. Response ", "Conversion Rates error. ");
  }
}

export async function fetchCandidateImportFailures(searchValue?: string, searchType?: string) {
  const params = { searchValue, searchType } as any;
  if (params.searchValue === null || params.searchValue === undefined || params.searchValue === "") delete params.searchValue;
  if (params.searchType === null || params.searchType === undefined || params.searchType === "") delete params.searchType;
  let response;
  try {
    response = await http().get("/api/candidateImportFailure/all", { params: params });
    return response.data;
  } catch (e) {
    showCatch(e, "Candidate import failures error. Response ", "Candidate Import Failures error. ");
  }
}

export async function fetchCammioStats(
  initDate: string,
  endDate: string,
  role: string,
  sortBy?: string,
  sortDirection?: string,
  page?: number,
  perPage?: number,
  downloadAll?: boolean,
  tenantId?: number
) {
  let props;
  if (downloadAll) {
    props = { tenantId, endDate, initDate, sortBy, sortDirection };
  } else {
    props = { tenantId, endDate, initDate, role, sortBy, sortDirection, page, perPage };
  }
  let response;
  try {
    response = await http().post("/api/cammioStats", props);
    return response.data;
  } catch (e) {
    showCatch(e, "Fetch Cammio Stats error. Response ", "Fetch Cammio Stats error. ");
  }
}

export async function postPhoneCallTranscript(candidateId: string, phaseIndex: number, transcript: any, summary?: boolean) {
  const data = { candidateId, phaseIndex, transcript };
  let url = "/api/phoneCallTranscript";
  if (summary) url = url + "?summary=true";
  let response;
  try {
    response = await http().post(url, data);
    return response.data;
  } catch (e) {
    showCatch(e, "phoneCallTranscript error. Response ", "phoneCallTranscript error. ");
  }
}

export async function generatePDF() {
  let response;
  try {
    response = await http(false, { Accept: "application/pdf" }).get(`/api/pdf/generator`);
    return response?.data;
  } catch (e) {
    showCatch(e, "generatePDF error. Response ", "generatePDF error. ");
  }
}

export async function getJobDescriptionPdf(id: any): Promise<any> {
  let response;
  try {
    response = await http().get("/api/internalPDF/" + id);
    return response;
  } catch (error) {
    return null;
  }
}
