import { generateQuestions } from "../../../../api/generative-ai";
import { getAllSkillsOptions } from "../../../recruitment/processes/edit/process-creation-helpers/process-creation-api-helper";

export enum CopilotActionID {
  InterviewQuestionGenerator_v0 = "INTERVIEW_QUESTION_GENERATOR_V0",
}

export enum CopilotActionType {
  multiSelect = "multiSelect",
  input = "input",
  simpleOptions = "simpleOptions",
}

export interface CopilotAction {
  id: CopilotActionID; // Action identifier
  label: string; // Main action title
  apiCall: Function; // API call function
  loadingText?: string; // This is an optional text that is displayed when the API call is triggered
  steps?: CopilotActionSteps[]; // This steps define some additional inputs needed to retrive extra data from the user
}

export interface CopilotActionSteps {
  label: string; // Step title
  type: CopilotActionType; // Action answer type
  id: string; // This is the id for the input on the form
  answers: CopilotActionAnswer[] | Function; // List of possible answers
  skip?: CopilotActionConditional[]; // This is the defenition of when this step should be skipped
}

export interface CopilotActionConditional {
  name: string; // Name of the field that will trigger the conditional
  values?: string[]; // Posible values of the field that will trigger the conditional
  nextStep?: string; // Next step id
}

interface CopilotActionAnswer {
  label: string; // Answer text or placeholder in case is an input type answer
  value?: string; // Answer internal value for multiple options answer types
  nextStep?: string; // Next step id
}

export const COPILOT_ACTION: CopilotAction[] = [
  {
    id: CopilotActionID.InterviewQuestionGenerator_v0,
    label: "Generate interview questions",
    loadingText: "Generating Questions",
    apiCall: generateQuestions,
    steps: [
      {
        id: "processNameAsJobTitle",
        label: "copilot.label.001",
        type: CopilotActionType.simpleOptions,
        answers: [
          { label: "Yes", value: "true", nextStep: "skills" },
          { label: "No", value: "false", nextStep: "jobTitle" },
        ],
        skip: [
          { name: "jobTitle", nextStep: "skills" },
          { name: "processNameAsJobTitle", values: ["true"], nextStep: "skills" },
        ],
      },
      {
        id: "jobTitle",
        label: "copilot.label.002",
        type: CopilotActionType.input,
        answers: [{ label: "Job Title", nextStep: "skills" }],
      },
      {
        id: "skills",
        label: "copilot.label.003",
        type: CopilotActionType.multiSelect,
        answers: getAllSkillsOptions,
      },
    ],
  },
];
