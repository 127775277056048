import React, { ReactElement, useState } from "react";

import { useSelector } from "react-redux";
import { ContextAction, CopilotState } from "../../../../redux/slices/copilotSlice";
import { CombinedState } from "../../../../redux/store/state";

import { COPILOT_ACTION, CopilotActionSteps } from "./copilot-actions";

import { Card, CardBody, CardHeader } from "@adeccoux/tag-ds";

import { toLiteral } from "../../../../helper/locale-utils";

import CopilotAction from "./components/copilot-action";

import "./recruiter-copilot.scss";

/**
 * Component that renders the recruiter copilot
 */
const RecruiterCopilot: React.FC<any> = (): ReactElement => {
  const copilotState: CopilotState = useSelector((state: CombinedState) => state.copilot);

  const { contextActions, userContextParamns } = copilotState;

  const [selectedAction, setSelectedAction] = useState<ContextAction>();

  const [dialogOpen, setDialogOpen] = useState(false);

  function closeDialog() {
    setDialogOpen(false)
    setSelectedAction(undefined)
  }

  return (
    <React.Fragment>
      {contextActions?.length > 0 && <div id="recruiter-copilot" className="tag-ds">
        {dialogOpen && (
          <Card id="copilot-dialog">
            <CardBody>
              <button className="button-card-icon" onClick={() => closeDialog()}>
                <span className="material-icons">
                  close
                </span>
              </button>
              {!selectedAction && <h5 className="mb3">{toLiteral({ id: "Hi! What can i help you with ?" })}</h5>}
              {contextActions?.map((contextAction) => (
                <CopilotAction key={contextAction?.id} contextAction={contextAction} userContextParamns={userContextParamns} setDialogOpen={setDialogOpen} setSelectedAction={setSelectedAction} selectedAction={selectedAction} />
              ))}
            </CardBody>
          </Card>
        )}
        <button type="button" id="copilot-bubble" onClick={() => setDialogOpen(true)} onMouseEnter={() => setDialogOpen(true)}>
          <span className="material-icons">auto_awesome</span>
        </button>
      </div>}
    </React.Fragment>

  );
};

export default RecruiterCopilot;
