import { TIMEZONES } from "../constants";
import { Candidate, PhaseStatus } from "../types";

export interface OutlookCalendars {
  id: string;
  name: string;
}

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function extractDays(defaulCalendar: any, availabilityDays: string[], halfDayFormat: boolean, capacities?: any) {
  let days: any = [];
  if (defaulCalendar[0] && availabilityDays.includes("0")) {
    days.push(changeFormat(defaulCalendar[0], halfDayFormat, capacities && capacities[0]));
  } else if (!defaulCalendar[0] && availabilityDays.includes("0")) {
    days.push([]);
  }
  if (defaulCalendar[1] && availabilityDays.includes("1")) {
    days.push(changeFormat(defaulCalendar[1], halfDayFormat, capacities && capacities[1]));
  } else if (!defaulCalendar[1] && availabilityDays.includes("1")) {
    days.push([]);
  }
  if (defaulCalendar[2] && availabilityDays.includes("2")) {
    days.push(changeFormat(defaulCalendar[2], halfDayFormat, capacities && capacities[2]));
  } else if (!defaulCalendar[2] && availabilityDays.includes("2")) {
    days.push([]);
  }
  if (defaulCalendar[3] && availabilityDays.includes("3")) {
    days.push(changeFormat(defaulCalendar[3], halfDayFormat, capacities && capacities[3]));
  } else if (!defaulCalendar[3] && availabilityDays.includes("3")) {
    days.push([]);
  }
  if (defaulCalendar[4] && availabilityDays.includes("4")) {
    days.push(changeFormat(defaulCalendar[4], halfDayFormat, capacities && capacities[4]));
  } else if (!defaulCalendar[4] && availabilityDays.includes("4")) {
    days.push([]);
  }
  if (defaulCalendar[5] && availabilityDays.includes("5")) {
    days.push(changeFormat(defaulCalendar[5], halfDayFormat, capacities && capacities[5]));
  } else if (!defaulCalendar[5] && availabilityDays.includes("5")) {
    days.push([]);
  }
  if (defaulCalendar[6] && availabilityDays.includes("6")) {
    days.push(changeFormat(defaulCalendar[6], halfDayFormat, capacities && capacities[6]));
  } else if (!defaulCalendar[6] && availabilityDays.includes("6")) {
    days.push([]);
  }
  return days;
}

export function changeFormat(day: string[], halfDayFormat: boolean, dayCapacities?: number[]) {
  let newDay: any[] = [];
  day.forEach((interval: string, indexInterval: number) => {
    const firstHour = interval.split("-")[0];
    const secondHour = interval.split("-")[1];
    let newFirstHour = firstHour;
    let newSecondHour = secondHour;
    if (halfDayFormat) {
      newFirstHour = transformHour(firstHour.split(":")[0], false)[0] + ":" + firstHour.split(":")[1];
      newSecondHour = transformHour(secondHour.split(":")[0], false)[0] + ":" + secondHour.split(":")[1];
    }
    const newInterval = newFirstHour + "-" + newSecondHour;
    if (dayCapacities) {
      newDay.push({
        interval: newInterval,
        format: [transformHour(firstHour.split(":")[0], false)[1], transformHour(secondHour.split(":")[0], false)[1]],
        capacity: dayCapacities[indexInterval],
      });
    } else {
      newDay.push({
        interval: newInterval,
        format: [transformHour(firstHour.split(":")[0], false)[1], transformHour(secondHour.split(":")[0], false)[1]],
      });
    }
  });
  return newDay;
}

export function errorTimeValue(value: string, format: boolean, halfDayFormat: boolean) {
  let alphaExp;
  if (!halfDayFormat) {
    alphaExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  } else {
    if (format) {
      alphaExp = /^(0[0-9]|1?[0-2]):[0-5][0-9]$/;
    } else {
      alphaExp = /^(1[0-1]|0?[0-9]):[0-5][0-9]$/;
    }
  }
  if (!value.match(alphaExp)) {
    return true;
  } else {
    return false;
  }
}

export function transformHour(hour: string, post: boolean, format?: any) {
  let newHour: string;
  let transformed = false;
  if (parseInt(hour) < 12 && !post) return [hour, false];
  if (!post) {
    if (hour === "12") {
      newHour = "00";
      transformed = true;
    } else {
      newHour = (parseInt(hour) - 12).toString().padStart(2, "0");
      transformed = true;
    }
  } else {
    if (hour === "12") {
      if (!format) newHour = "12";
      else newHour = hour;
    } else {
      if (!format) newHour = hour;
      else newHour = (parseInt(hour) + 12).toString().padStart(2, "0");
    }
  }

  return [newHour, transformed];
}

export function convertDefaultCalendarToObject(calendar: any, timezone: string, availabilityDays: any, halfDayFormat: boolean) {
  const newAvailabilityDays: { [k: string]: any } = {};
  const newCapacityDays: { [k: string]: any } = {};

  availabilityDays.forEach((day: string, index: number) => {
    if (calendar[index]) {
      let newIntervals: any[] = [];
      let newCapacities: any[] = [];
      calendar[index].forEach((interval: any) => {
        let newInterval = getNewIntervalsFormat(interval, halfDayFormat);
        newIntervals.push(newInterval);
        newCapacities.push(interval.capacity);
      });
      newAvailabilityDays[day] = newIntervals;
      newCapacityDays[day] = newCapacities;
    } else {
      newAvailabilityDays[day] = calendar[index];
    }
  });
  const newCalendar = {
    timeZone: timezone,
    availabilityDays: newAvailabilityDays,
    capacities: newCapacityDays,
  };
  return newCalendar;
}

export function getNewIntervalsFormat(interval: any, halfDayFormat: boolean) {
  let newInterval = interval.interval;
  const firstHour = interval.interval.split("-")[0];
  const secondHour = interval.interval.split("-")[1];
  let newFirstHour = firstHour;
  let newSecondHour = secondHour;
  if (halfDayFormat) {
    newFirstHour = transformHour(firstHour.split(":")[0], true, interval.format[0])[0] + ":" + firstHour.split(":")[1];

    newSecondHour = transformHour(secondHour.split(":")[0], true, interval.format[1])[0] + ":" + secondHour.split(":")[1];
  }

  newInterval = newFirstHour + "-" + newSecondHour;
  return newInterval;
}

export const sortDays: any = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };

export function getAvailabilityDays(itemAvailabilityDays: any) {
  const orderAvailabilityDays = Object.keys(itemAvailabilityDays).sort((a: string, b: string) => sortDays[a] - sortDays[b]);

  let sortAvailabilityDays: any = {};
  for (const [index, day] of orderAvailabilityDays.entries()) {
    sortAvailabilityDays[index] = itemAvailabilityDays[day];
  }
  let newAvailabilityDays: string[] = [];
  for (const day of Object.keys(sortAvailabilityDays)) {
    if (sortAvailabilityDays[day]) {
      newAvailabilityDays.push(day);
    }
  }
  return newAvailabilityDays;
}

export function getFormatAvailabilitySlots(itemAvailabilitySlots: any, itemAvailabilityCapacities: any, sortAvailabilityDays: string[], dayFormat: any) {
  const orderAvailabilitySlots = Object.keys(itemAvailabilitySlots).sort((a: string, b: string) => sortDays[a] - sortDays[b]);
  const orderAvailabilityCapacities = Object.keys(itemAvailabilityCapacities).sort((a: string, b: string) => sortDays[a] - sortDays[b]);

  let sortAvailabilitySlots: any = {};
  for (const day of orderAvailabilitySlots) {
    sortAvailabilitySlots[sortDays[day]] = itemAvailabilitySlots[day];
  }

  let sortAvailabilityCapacities: any = {};
  for (const day of orderAvailabilityCapacities) {
    sortAvailabilityCapacities[sortDays[day]] = itemAvailabilityCapacities[day];
  }
  return extractDays(sortAvailabilitySlots, sortAvailabilityDays, dayFormat, sortAvailabilityCapacities);
}

export function getTodayYear() {
  return new Date().getFullYear();
}
export function getTodayMonth() {
  return new Date().getMonth() + 1;
}
export function getDayOfYear(date?: Date) {
  var now = date || new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff = now.valueOf() - start.valueOf() + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
}

/**
 *
 * @param valuesDates Is a array with startMonth, startYear, endMonth, EndYear values (In this order)
 * @param validationsNames Is a array with startMonth, startYear, endMonth, EndYear names (In this order)
 * @returns
 */
export function validateStartEndMonthYear(valuesDates: string[], validationsNames: string[]) {
  const validations: any = {};
  if (valuesDates?.length === 4 && validationsNames?.length === 4) {
    let validateDates = true;
    let startMonth: string | number = valuesDates[0];
    let startYear: string | number = valuesDates[1];
    let endMonth: string | number = valuesDates[2];
    let endYear: string | number = valuesDates[3];
    if (startMonth && startYear && endMonth && endYear) {
      startMonth = parseInt(startMonth);
      startYear = parseInt(startYear);
      endMonth = parseInt(endMonth);
      endYear = parseInt(endYear);
      if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
        validateDates = false;
      }
      validations[validationsNames[0]] = validateDates;
      validations[validationsNames[1]] = validateDates;
      validations[validationsNames[2]] = validateDates;
      validations[validationsNames[3]] = validateDates;
    }
  } else {
    console.error("Not all parameters needed was passed", valuesDates, validationsNames);
  }
  return validations;
}

export function validationMonthYear(valuesDates: string[], validationsNames: string[]) {
  const validations: any = {
    [validationsNames[0]]: true,
    [validationsNames[1]]: true,
  };

  let month: string | number = valuesDates[0];
  let year: string | number = valuesDates[1];
  month = parseInt(month);
  year = parseInt(year);

  if (month || year) {
    if (isNaN(month)) {
      validations[validationsNames[0]] = false;
    } else if (isNaN(year)) {
      validations[validationsNames[1]] = false;
    } else {
      if (month > getTodayMonth() && year >= getTodayYear()) {
        validations[validationsNames[0]] = false;
        validations[validationsNames[1]] = false;
      }
    }
  }
  return validations;
}

/**
 *
 * @param response response of phaseResults
 * @param phase number of the phase
 * @param cb Function that change the status to booked
 * @returns
 */

export function isCurrentDateBooked(response: Candidate, phase: number, bodySent: Candidate, cb?: any) {
  let booked = false;
  if (response?.phasesResults) {
    const type = response?.phasesResults[phase]?.type;
    const currentPhase = response?.phasesResults[phase]?.phoneCall || response?.phasesResults[phase]?.liveInterview;
    const bodyPhaseSent = bodySent?.phasesResults[phase]?.phoneCall || bodySent?.phasesResults[phase]?.liveInterview;
    const status = response?.phasesStats?.find((stat: any) => stat?.type === type)?.status;
    if (bodyPhaseSent?.scheduleDate !== currentPhase?.scheduleDate || (!currentPhase?.recruiterCalendarEventId && status === PhaseStatus.invited)) {
      booked = true;
    }
  }
  if (cb) cb(booked);
  return booked;
}

export function getTimezoneOptions() {
  let options: any[] = [];
  Object.keys(TIMEZONES)?.forEach((timezoneKey) => {
    options.push({ key: (TIMEZONES as any)?.[timezoneKey], label: timezoneKey });
  });
  return options;
}
